export const bill_value_keys = [
  'cost1_value',
  'cost2_value',
  'cost3_value',
  'consumption1_value',
  'consumption2_value',
  'consumption3_value',
  'demand1_value',
  'demand2_value',
];

export function handleAggregateBills(allBills) {
  let agg_bill_list = {};
  allBills.forEach((bill) => {
    let bill_key = bill.defaults.bill_key;
    if (Object.keys(agg_bill_list).includes(bill_key)) {
      agg_bill_list[bill_key].push(bill);
    } else {
      agg_bill_list[bill_key] = [bill];
    }
  });

  let agg_bills = {};
  Object.keys(agg_bill_list).forEach((key) => {
    agg_bills[key] = aggregateBills(agg_bill_list[key]);
  });

  return Object.values(agg_bills);
}

export function aggregateBills(billList) {
  let agg_bill = {};

  agg_bill = { ...billList[0].defaults };
  agg_bill.pdf = billList[0].pdf;
  agg_bill.id = agg_bill.bill_key;

  if (billList.length > 0) {
    for (let i = 1; i < billList.length; i++) {
      bill_value_keys.forEach((key) => {
        agg_bill[key] += billList[i].defaults[key];
      });
      if (billList[i].pdf) {
        agg_bill.pdf = billList[i].pdf;
      }
    }
  }
  return agg_bill;
}

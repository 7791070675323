import React, { useEffect, useState, useCallback } from 'react';
import { Container, Col, Row, Card, Alert } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faPlus,
  faSyncAlt,
  faBolt,
  faBoltSlash,
} from '@fortawesome/pro-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import { ApiServiceServerless } from '../../xhr_libs';
import { useOrganizationRoles } from '../../helpers/checkRoles';
import EnergyTracerTable from '../../components/tables';
import Loader from '../../components/Loader';
import PortfolioManagerModal from '../modals/PortfolioManager/PortfolioManagerModal';
import { UTILITY_TYPES, UTILITY_TYPES_OPTS } from '../../helpers/utility-types';
import FilterInput from '../FilterInput';
import FilterSelect from '../FilterSelect';
import {
  getUserOrganizationPreference,
  setUserOrganizationPreference,
} from '../../helpers/user-preferences';
import ConfirmationModal, { useConfirm } from '../modals/ConfirmationModal';
import PortfolioManagerAutoSyncAddModal from '../modals/PortfolioManager/PortfolioManagerAutoSyncAddModal';
import PortfolioManagerAutoSyncEditModal from '../modals/PortfolioManager/PortfolioManagerAutoSyncEditModal';

export default function PortfolioManager(props) {
  const { organization, userSelectedOrganizationDetails, showToast } = props;

  const [accounts, setAccounts] = useState([]);
  const [secondaryMeterIds, setSecondaryMeterIds] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [activeAccount, setActiveAccount] = useState({});

  const [showSecondaryMeterIds, setShowSecondaryMeterIds] = useState(false);
  const [accountFilterValue, setAccountFilterValue] = useState('');
  const [utilityFilterValue, setUtilityFilterValue] = useState(0);
  const [buildingFilterValue, setBuildingFilterValue] = useState('');

  const [manualRefresh, setManualRefresh] = useState(0);

  const [activeLicense, setActiveLicense] = useState(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const createAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'portfolio_manager',
    'create'
  );
  const updateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'portfolio_manager',
    'update'
  );
  const deleteAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'portfolio_manager',
    'delete'
  );

  useEffect(() => {
    let activeLicense = false;
    if (organization.id) {
      if (
        organization &&
        organization.licenses &&
        'portfolio_manager' in organization.licenses
      ) {
        activeLicense = organization.licenses.portfolio_manager;
      }
    }
    setActiveLicense(activeLicense);
  }, [organization]);

  const formatTableData = (
    accounts,
    secondaryMeterIds,
    showSecondaryMeterIds,
    accountFilterValue,
    utilityFilterValue,
    buildingFilterValue
  ) => {
    let filtered_accounts = [...accounts];

    //secondary meter id filter
    if (!showSecondaryMeterIds) {
      filtered_accounts = filtered_accounts.filter(
        (acc) => !secondaryMeterIds.includes(acc.reference_id)
      );
    }

    //utility filter
    if (utilityFilterValue && parseInt(utilityFilterValue) !== 0) {
      filtered_accounts = filtered_accounts.filter(
        (acc) =>
          acc.account_type &&
          acc.account_type.toLowerCase() ===
            UTILITY_TYPES_OPTS[parseInt(utilityFilterValue)].toLowerCase()
      );
    }

    // account filter
    if (accountFilterValue !== '') {
      filtered_accounts = filtered_accounts.filter(
        (acc) =>
          acc.account_number &&
          acc.account_number
            .toLowerCase()
            .includes(accountFilterValue.toLowerCase())
      );
    }

    // building filter
    if (buildingFilterValue !== '') {
      filtered_accounts = filtered_accounts.filter(
        (acc) =>
          acc.building_name &&
          acc.building_name
            .toLowerCase()
            .includes(buildingFilterValue.toLowerCase())
      );
    }

    return setFilteredAccounts(filtered_accounts);
  };

  useEffect(() => {
    formatTableData(
      accounts,
      secondaryMeterIds,
      showSecondaryMeterIds,
      accountFilterValue,
      utilityFilterValue,
      buildingFilterValue
    );
  }, [
    accounts,
    secondaryMeterIds,
    showSecondaryMeterIds,
    accountFilterValue,
    utilityFilterValue,
    buildingFilterValue,
  ]);

  const formatAccounts = (accounts) => {
    let local_accounts = [];
    accounts.forEach((acc) => {
      local_accounts.push({
        ...acc,
        building_name:
          acc.buildings && acc.buildings.length > 0
            ? acc.buildings[0].name
            : '',
      });
    });
    return local_accounts;
  };

  const getLocalAccounts = useCallback(() => {
    if (userSelectedOrganizationDetails.id) {
      setIsLoadingAccounts(true);
      ApiServiceServerless.get(
        `/portfolio_manager/accounts/${userSelectedOrganizationDetails.id}`,
        {
          authorization_id: userSelectedOrganizationDetails.id,
        }
      )
        .then((res) => {
          setAccounts(formatAccounts(res.data.accounts));
          setSecondaryMeterIds(getSecondaryMeterIds(res.data.accounts));
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          throw error;
        })
        .finally(() => {
          setIsLoadingAccounts(false);
        });
    }
  }, [userSelectedOrganizationDetails, showToast]);

  const getSecondaryMeterIds = (accounts) => {
    let secondary_meter_ids = [];
    accounts.forEach((acc) => {
      if (acc.reference_id2 && acc.reference_id2 !== '') {
        secondary_meter_ids.push(acc.reference_id2);
      }
    });
    return secondary_meter_ids;
  };

  useEffect(() => {
    getLocalAccounts();
  }, [getLocalAccounts]);

  useEffect(() => {
    if (manualRefresh > 0) {
      getLocalAccounts();
      setManualRefresh(0);
    }
  }, [manualRefresh, getLocalAccounts]);

  const handleSetModal = (account_id, opt) => {
    let acc = accounts.find((acc) => acc.id === account_id);
    setActiveAccount(acc);
    setUserOrganizationPreference('lsa', acc.organization.id, acc.id);
    if (opt === 'sync') {
      setShowSyncModal(true);
    }
    if (opt === 'add') {
      setShowAddModal(true);
    }
    if (opt === 'edit') {
      setShowEditModal(true);
    }
  };

  const handleDelete = (account_id) => {
    const msg = `Are you sure you want to delete this auto sync? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteAutoSync(account_id);
    });
  };

  const deleteAutoSync = (account_id) => {
    setIsDeleting(true);
    let acc = accounts.find((acc) => acc.id === account_id);
    ApiServiceServerless.delete(
      `/portfolio_manager/auto/${acc.pm_auto_sync.id}`,
      {
        authorization_id: userSelectedOrganizationDetails.id,
      }
    )
      .then(() => {
        showToast('success', 'Success', 'Auto Sync Deleted');
        setAccounts((prev) =>
          prev.map((acc) => {
            if (acc.id !== account_id) {
              return acc;
            }
            acc.pm_auto_sync = null;
            return acc;
          })
        );
        setActiveAccount({});
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const tableColumns = [
    {
      dataField: 'account_number',
      text: 'Account Number',
      classes: (_cell, row) =>
        columnClasses(row, userSelectedOrganizationDetails.id),
    },

    {
      dataField: 'account_type',
      text: 'Account Type',

      classes: (_cell, row) =>
        columnClasses(row, userSelectedOrganizationDetails.id),
      formatter: (cell) => (
        <>{cell in UTILITY_TYPES ? UTILITY_TYPES[cell] : cell}</>
      ),
    },
    {
      dataField: 'building_name',
      text: 'Building Name',
      classes: (_cell, row) =>
        columnClasses(row, userSelectedOrganizationDetails.id),
    },
  ];

  const getTableStyles = (disabled) => {
    return {
      fontSize: '18px',
      lineHeight: 'inherit',
      position: 'relative',
      left: '-1px',
      color: 'var(--et_tab_grey)',
      cursor: disabled ? 'none' : 'pointer',
      opacity: disabled ? '0.5' : '1.0',
    };
  };

  const actionColumns = [
    {
      dataField: 'id',
      text: 'Actions',
      classes: (_cell, row) =>
        columnClasses(row, userSelectedOrganizationDetails.id),
      formatExtraData: {
        isDeleting: isDeleting,
        showAddModal: showAddModal,
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <>
          {activeLicense && (
            <>
              {updateAccess && (
                <FontAwesomeIcon
                  icon={faSyncAlt}
                  style={getTableStyles(formatExtraData.isDeleting)}
                  onClick={() => handleSetModal(cell, 'sync')}
                />
              )}
              {createAccess && !row.pm_auto_sync && (
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{
                    ...getTableStyles(formatExtraData.isDeleting),
                    left: '5px',
                  }}
                  onClick={() => handleSetModal(cell, 'add')}
                />
              )}
              {updateAccess && row.pm_auto_sync && row.pm_auto_sync.id && (
                <FontAwesomeIcon
                  icon={faPen}
                  style={{
                    ...getTableStyles(formatExtraData.isDeleting),
                    fontSize: '16px',
                    left: '5px',
                  }}
                  onClick={() => handleSetModal(cell, 'edit')}
                />
              )}
              {deleteAccess && row.pm_auto_sync && row.pm_auto_sync.id && (
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={{
                    ...getTableStyles(formatExtraData.isDeleting),
                    left: '15px',
                  }}
                  onClick={() => handleDelete(cell)}
                />
              )}
            </>
          )}
        </>
      ),
    },
  ];

  const columnClasses = (row, organizationId) => {
    return row.id === getUserOrganizationPreference('lsa', organizationId)
      ? 'et-table-selected-row'
      : '';
  };

  const getStyles = (disabled) => {
    return {
      fontSize: '20px',
      margin: '0 0.35em',
      verticalAlign: 'text-bottom',
      cursor: disabled ? 'none' : 'pointer',
      color: 'var(--et_tab_grey)',
      opacity: disabled ? '0.5' : '1.0',
    };
  };

  return (
    <Card.Body>
      {!activeLicense && (
        <Alert style={{ width: '90%' }} variant='danger'>
          Warning: This license is expired. Please contact an administrator
          about renewing this license.
        </Alert>
      )}
      <Row>
        <Col xs={12}>
          {isLoadingAccounts ? (
            <Container>
              <Row>
                <Col
                  style={{
                    paddingBottom: '10vh',
                    paddingTop: '5vh',
                    paddingLeft: '49%',
                  }}
                >
                  <Loader />
                </Col>
              </Row>
            </Container>
          ) : (
            <>
              <h5>Manage Portfolio Manager Meters</h5>
              <Row>
                <FilterInput
                  label='Filter Account'
                  setFilterValue={setAccountFilterValue}
                  size={3}
                  organization_id={userSelectedOrganizationDetails.id}
                  preference_key='anf'
                />
                <FilterInput
                  label='Filter Building'
                  setFilterValue={setBuildingFilterValue}
                  size={3}
                  organization_id={userSelectedOrganizationDetails.id}
                  preference_key='bnf'
                />

                <FilterSelect
                  label='Utility Type'
                  options={UTILITY_TYPES_OPTS}
                  setFilterValue={setUtilityFilterValue}
                  size={2}
                  organization_id={userSelectedOrganizationDetails.id}
                  preference_key={'atf'}
                  empty_preference={'0'}
                />
                <Col>
                  <FontAwesomeIcon
                    style={{
                      fontSize: '20px',
                      margin: '0 0.35em',
                      verticalAlign: 'text-bottom',
                      cursor: 'pointer',
                      color: 'var(--et_tab_grey)',
                      position: 'absolute',
                      bottom: '35%',
                    }}
                    icon={!showSecondaryMeterIds ? faBoltSlash : faBolt}
                    title={'Show Solar/ Secondary Meters'}
                    onClick={() => setShowSecondaryMeterIds((prev) => !prev)}
                  />
                </Col>

                <Col>
                  <div
                    style={{
                      position: 'absolute',
                      right: '1em',
                      bottom: '1em',
                    }}
                  >
                    <FontAwesomeIcon
                      style={getStyles(isLoadingAccounts)}
                      icon={faSyncAlt}
                      title={'Refresh Data'}
                      disabled={isLoadingAccounts}
                      onClick={() => setManualRefresh(1)}
                    />
                  </div>
                </Col>
              </Row>
              <EnergyTracerTable
                style={{ margin: '0px', padding: '0px' }}
                keyField='id'
                data={sortBy(filteredAccounts, ['account_number'])}
                columns={[].concat(tableColumns, actionColumns)}
              />
            </>
          )}
        </Col>
      </Row>
      <PortfolioManagerModal
        show={showSyncModal}
        onHide={() => {
          setShowSyncModal(false);
        }}
        account={activeAccount}
        showToast={showToast}
      />
      <PortfolioManagerAutoSyncAddModal
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
        }}
        account={activeAccount}
        setAccounts={setAccounts}
        showToast={showToast}
      />
      <PortfolioManagerAutoSyncEditModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        account={activeAccount}
        setAccounts={setAccounts}
        showToast={showToast}
      />
      <ConfirmationModal {...confirmationModalProps} />
    </Card.Body>
  );
}

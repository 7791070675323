import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { ApiServiceServerless } from '../../../xhr_libs';
import SmallerLoader from '../../SmallerLoader';

import { DAY_OPTS, MONTH_OFFSET_OPTS } from './';
import {
  PortfolioManagerDayInfo,
  PortfolioManagerMonthInfo,
} from './PortfolioManagerInfo';

export default function PortfolioManagerAutoSyncAddModal(props) {
  const { show, onHide, account, setAccounts, showToast } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [day, setDay] = useState(1);
  const [monthOffset, setMonthOffset] = useState(1);

  useEffect(() => {
    if (show) {
      setDay(1);
      setMonthOffset(1);
    }
  }, [show]);

  const addPortfolioManagerAutoSync = useCallback(
    (account, day, monthOffset) => {
      setIsLoading(true);
      const payload = {
        day: day,
        month_offset: monthOffset,
      };
      ApiServiceServerless.post(
        `/portfolio_manager/auto/${account.id}`,
        payload,
        {
          authorization_id: account.organization.id,
        }
      )
        .then((res) => {
          setAccounts((prev) =>
            prev.map((acc) => {
              if (acc.id !== account.id) {
                return acc;
              }
              acc.pm_auto_sync = res.data;
              return acc;
            })
          );
          showToast('success', 'Success', 'Auto Sync created');
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          throw error;
        })
        .finally(() => {
          setIsLoading(false);
          onHide();
        });
    },
    [showToast, setAccounts, onHide]
  );

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton={!isLoading}>
        Add Portfolio Manager Auto Sync
      </Modal.Header>
      <Modal.Body
        style={{ minHeight: '200px', maxHeight: '500px', overflowY: 'auto' }}
      >
        <Form>
          <Form.Group>
            <Form.Label>
              Day of Month
              <PortfolioManagerDayInfo />
            </Form.Label>
            <Form.Control
              as='select'
              required
              value={day}
              onChange={(e) => setDay(parseInt(e.target.value))}
            >
              {DAY_OPTS.map((opt) => (
                <option key={`day-opt-${opt}`} value={opt}>
                  {opt}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Month Offset <PortfolioManagerMonthInfo />
            </Form.Label>
            <Form.Control
              as='select'
              required
              value={monthOffset}
              onChange={(e) => setMonthOffset(parseInt(e.target.value))}
            >
              {MONTH_OFFSET_OPTS.map((opt) => (
                <option key={`month-opt-${opt}`} value={opt}>
                  {opt}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {isLoading && <SmallerLoader />}
        <Button
          variant='success'
          disabled={isLoading}
          onClick={() => addPortfolioManagerAutoSync(account, day, monthOffset)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import PopoverTrigger from '../../../../components/PopoverTrigger';
import CHARTCOLORS from '../../../../helpers/chartColors';

const style = {
  fontSize: '18px',
  color: CHARTCOLORS[1],
  marginLeft: '5px',
  cursor: 'pointer',
};

export function PortfolioManagerDayInfo() {
  return (
    <PopoverTrigger
      rootClose={true}
      popoverTitle={'Day of Month Desciption'}
      popoverContent={
        <div>Day of the month to perform a sync to Portfolio Manager</div>
      }
      trigger={'click'}
      placement='right'
    >
      <FontAwesomeIcon style={style} icon={faInfoCircle} />
    </PopoverTrigger>
  );
}

export function PortfolioManagerMonthInfo() {
  return (
    <PopoverTrigger
      rootClose={true}
      popoverTitle={'Month Offset Desciption'}
      popoverContent={
        <div>
          Length of time from current month to month to perform sync of data.
          Zero would be the current month, one would be the previous month, and
          so on.
        </div>
      }
      trigger={'click'}
      placement='right'
    >
      <FontAwesomeIcon style={style} icon={faInfoCircle} />
    </PopoverTrigger>
  );
}

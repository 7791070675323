import React, { useCallback, useEffect, useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';

import { sortBy, uniqBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSolarPanel, faSyncAlt } from '@fortawesome/pro-solid-svg-icons';

import ExpansionArrow from '../../components/ExpansionArrow';

import {
  getTitleName,
  getCredentialColumns,
  getAccountColumns,
  handleFilterAccountByCredential,
} from './helpers/provider';
import AddOrganizationSolarProvider from '../modals/ProviderModals/AddOrganizationSolarProvider';
import SolarLinkMeterModal from '../../components/modals/SolarLinkMeterModal';
import EnergyTracerTable from '../../components/tables';
import { useOrganizationRoles } from '../../helpers/checkRoles';
import Loader from '../../components/Loader';
import ApiServiceServerless from '../../xhr_libs';

export default function SolarUtilityImportSettings(props) {
  const { organization, provider_type, showToast } = props;

  const [CREDENTIAL_COLUMNS, setCredentialColumns] = useState([]);
  const [ACCOUNT_COLUMNS, setAccountColumns] = useState([]);

  const [solarProviders, setSolarProviders] = useState([]);
  const [solarMeters, setSolarMeters] = useState([]);
  const [formattedSolarMeters, setFormattedSolarMeters] = useState([]);

  const [subAccounts, setSubAccounts] = useState([]);

  const [showAddSolarOrgIdModal, setShowAddSolarOrgIdModal] = useState(false);
  const [showLinkSolarModal, setShowLinkSolarModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [activeLicense, setActiveLicense] = useState(false);

  const updateAccess = useOrganizationRoles(
    organization,
    'providers',
    'update'
  );

  useEffect(() => {
    let activeLicense = false;
    if (organization.id) {
      if (
        organization &&
        organization.licenses &&
        'solar' in organization.licenses
      ) {
        activeLicense = organization.licenses['solar'];
      }
    }
    setActiveLicense(activeLicense);
  }, [organization]);

  const [linkModalData, setLinkModalData] = useState({
    meter_id: '',
    org_id: '',
    status: '',
    name: '',
    sub_account_id: '',
    organization_solar_provider_id: '',
    solar_active: true,
  });

  const handleOpenLinkModal = useCallback((selectedRow) => {
    let local_row = { ...selectedRow };
    setLinkModalData(local_row);
    setShowLinkSolarModal(true);
  }, []);

  useEffect(() => {
    getCredentialColumns(provider_type, setCredentialColumns);
  }, [setCredentialColumns, provider_type]);

  useEffect(() => {
    getAccountColumns(
      provider_type,
      setAccountColumns,
      handleOpenLinkModal,
      updateAccess
    );
  }, [provider_type, handleOpenLinkModal, updateAccess]);

  const refreshData = async (organizationId, showToast, setIsLoading) => {
    setIsLoading(true);
    await ApiServiceServerless.get(`/solar/providers/${organizationId}`, {
      authorization_id: organizationId,
    })
      .then((res) => {
        setSolarProviders(res.data.providers);
        setSolarMeters(res.data.meters);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
      });

    await ApiServiceServerless.get(`/solar/sub_accounts/${organizationId}`, {
      authorization_id: organizationId,
    })
      .then((res) => {
        setSubAccounts(res.data);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      });
    setIsLoading(false);
  };

  useEffect(() => {
    if (organization && organization.id) {
      refreshData(organization.id, showToast, setIsLoading);
    }
  }, [organization, showToast]);

  useEffect(() => {
    let formatted_meter_data = [];
    solarMeters.forEach((meter) => {
      let local_meter_data = {
        ...meter,

        sub_account_id: '',
        sub_account_name: '',
      };
      const sub_account = subAccounts.find(
        (sub_account) =>
          sub_account.solar_meter_id === meter.meter_id &&
          sub_account.provider_meter_id !== meter.meter_id
      );
      if (sub_account) {
        local_meter_data.sub_account_id = sub_account.id;
        local_meter_data.solar_active = sub_account.solar_active;
      }
      formatted_meter_data.push(local_meter_data);
    });
    setFormattedSolarMeters(formatted_meter_data);
  }, [solarMeters, subAccounts]);

  const filterAccountsByCredential = (credential, account) => {
    return handleFilterAccountByCredential(
      provider_type,
      credential,
      account,
      true
    );
  };

  const EXPAND_ROW = {
    renderer: (row) => (
      <div
        style={{
          boxShadow: 'inset 1px 2px 3px 0px #eeeeee',
          margin: '-5px',
          paddingTop: '16px',
          paddingRight: '16px',
        }}
      >
        <Row>
          {isLoading ? (
            <Col style={{ height: '40px', marginBottom: '25px' }}>
              <Loader />
            </Col>
          ) : (
            <>
              <Col xs={1} />
              <Col xs={11}>
                <EnergyTracerTable
                  style={{ margin: '0px', padding: '0px' }}
                  keyField='meter_id'
                  data={sortBy(
                    uniqBy(
                      formattedSolarMeters.filter((acct) =>
                        filterAccountsByCredential(row, acct)
                      ),
                      'meter_id'
                    ),
                    'name'
                  )}
                  columns={ACCOUNT_COLUMNS}
                  noDataIndication={
                    <div style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                      There are no available accounts or discovery is still
                      pending. Please note that discovery can take up to 48
                      hours.
                    </div>
                  }
                />
              </Col>
            </>
          )}
        </Row>
      </div>
    ),
    showExpandColumn: true,
    expandColumnPosition: 'right',
    expandHeaderColumnRenderer: ({ isAnyExpands: expanded }) => (
      <>
        <span style={{ marginRight: '10px' }}>Expand All</span>
        <span>
          <ExpansionArrow expanded={expanded} />
        </span>
      </>
    ),
    expandColumnRenderer: ({ expanded }) => (
      <>
        <span style={{ marginRight: '10px' }}>Expand</span>
        <span>
          <ExpansionArrow expanded={expanded} />
        </span>
      </>
    ),
  };

  const getStyles = (disabled) => {
    return {
      iconStyle: {
        fontSize: '20px',
        margin: '0 0.35em',
        verticalAlign: 'text-bottom',
        cursor: disabled ? 'none' : 'pointer',
        color: 'var(--et_tab_grey)',
        opacity: disabled ? '0.5' : '1.0',
      },
    };
  };

  return (
    <>
      <Card.Body>
        <Row>
          <Col style={{ marginBottom: '10px' }} xs={12}>
            <h5 style={{ marginBottom: 0 }}>
              {'Manage ' + getTitleName(provider_type) + ' Meters'}
            </h5>
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: '12px',
              }}
            >
              {updateAccess && activeLicense && (
                <FontAwesomeIcon
                  style={getStyles(isLoading).iconStyle}
                  icon={faSolarPanel}
                  title={'Add Solar Provider'}
                  disabled={isLoading}
                  onClick={() => setShowAddSolarOrgIdModal(true)}
                />
              )}
              <FontAwesomeIcon
                style={getStyles(isLoading).iconStyle}
                icon={faSyncAlt}
                title={'Refresh Data'}
                disabled={isLoading}
                onClick={() =>
                  refreshData(organization.id, showToast, setIsLoading)
                }
              />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '2em' }}>
          <Col>
            {isLoading ? (
              <Container>
                <Row>
                  <Col
                    style={{
                      paddingBottom: '10vh',
                      paddingTop: '5vh',
                      paddingLeft: '49%',
                    }}
                  >
                    <Loader />
                  </Col>
                </Row>
              </Container>
            ) : (
              <>
                {CREDENTIAL_COLUMNS.length > 0 && (
                  <EnergyTracerTable
                    headerClasses='bg-primary text-light'
                    keyField='id'
                    data={solarProviders}
                    columns={CREDENTIAL_COLUMNS}
                    expandRow={EXPAND_ROW}
                    noDataIndication={
                      <div
                        style={{ paddingTop: '30px', paddingBottom: '30px' }}
                      >
                        You have not connected any solar providers to this
                        organization.
                      </div>
                    }
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </Card.Body>
      <AddOrganizationSolarProvider
        show={showAddSolarOrgIdModal}
        onHide={() => setShowAddSolarOrgIdModal(false)}
        organizationId={organization.id}
        refreshData={refreshData}
        setIsLoading={setIsLoading}
        showToast={showToast}
      />
      <SolarLinkMeterModal
        show={showLinkSolarModal}
        onHide={() => setShowLinkSolarModal(false)}
        organization={organization}
        modalData={linkModalData}
        subAccounts={subAccounts}
        setSubAccounts={setSubAccounts}
        updateAccess={updateAccess}
        activeLicense={activeLicense}
        showToast={showToast}
      />
    </>
  );
}

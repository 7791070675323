import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import dayjs from 'dayjs';

import { ApiServiceServerless } from '../../../xhr_libs';
import SmallerLoader from '../../SmallerLoader';
import MonthSelect from '../../MonthSelect2';

const utility_types = ['electric', 'gas'];

export default function PortfolioManagerModal(props) {
  const { show, onHide, account, showToast } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [utilityType, setUtilityType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    setUtilityType(account.account_type);
  }, [account]);

  useEffect(() => {
    const local_today = dayjs.utc(new Date());
    const local_start_date = dayjs.utc(new Date(local_today.year(), 0, 1));
    setStartDate(local_start_date);
    setEndDate(local_start_date.add(11, 'month'));
    setMinDate(dayjs.utc(new Date(2010, 0, 1)));
    setMaxDate(dayjs.utc(new Date(local_today.year() + 1, 11, 31)));
  }, []);

  const updatePortfolioManagerMeter = useCallback(
    (account, utilityType, startDate, endDate) => {
      setIsLoading(true);
      const local_start_date = dayjs.utc(startDate);
      const local_end_date = dayjs.utc(endDate);
      const payload = {
        utility_type: utilityType,
        start_year: local_start_date.year(),
        start_month: local_start_date.month() + 1,
        end_year: local_end_date.year(),
        end_month: local_end_date.month() + 1,
      };
      ApiServiceServerless.post(
        `/portfolio_manager/meter/${account.id}`,
        payload,
        {
          authorization_id: account.organization.id,
        }
      )
        .then(() => {
          showToast(
            'success',
            'Success',
            'Account is syncing. This may take a few minutes.'
          );
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          throw error;
        })
        .finally(() => {
          setIsLoading(false);
          onHide();
        });
    },
    [showToast, onHide]
  );

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton={!isLoading}>
        Sync Portfolio Manager Account
      </Modal.Header>
      <Modal.Body
        style={{ minHeight: '200px', maxHeight: '500px', overflowY: 'auto' }}
      >
        <Form>
          <Form.Group>
            <Form.Label>Utility Account</Form.Label>
            <Form.Control disabled={true} value={account.account_number} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Bill type</Form.Label>
            <Form.Control
              as='select'
              required
              onChange={(e) => setUtilityType(e)}
            >
              {utility_types.map((type) => (
                <option key={`type-opt-${type}`} value={type}>
                  {type.charAt(0).toUpperCase() + type.substr(1).toLowerCase()}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <MonthSelect
            label='Start Date'
            currentDate={startDate}
            setCurrentDate={setStartDate}
            min_date={minDate}
            max_date={maxDate}
            showPicker={true}
          />
          <MonthSelect
            label='End Date'
            currentDate={endDate}
            setCurrentDate={setEndDate}
            min_date={minDate}
            max_date={maxDate}
            showPicker={true}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {isLoading && <SmallerLoader />}
        <Button
          variant='success'
          disabled={
            utilityType === '' ||
            startDate === '' ||
            endDate === '' ||
            isLoading
          }
          onClick={() =>
            updatePortfolioManagerMeter(
              account,
              utilityType,
              startDate,
              endDate
            )
          }
        >
          {isLoading ? 'Syncing...' : 'Sync'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';

import { fmtBoolExists } from '../../../components/tables/formatters';

const styles = {
  buttonContainer: { width: '60%', textAlign: 'left', margin: 'auto' },
  buttonIcon: { marginRight: '.3rem', padding: '0px' },
};

export const SOLAR_PROVIDER_LOOKUP = {
  0: 'LightLevel',
  1: 'Tesla',
  2: 'Also Energy',
};

export const SOLAR_CREDENTIAL_COLUMNS = [
  {
    dataField: 'solar_provider_type',
    text: 'Solar Provider Type',
    formatter: (cell) => SOLAR_PROVIDER_LOOKUP[cell],
  },
  {
    dataField: 'solar_provider_id',
    text: 'Solar Provider Id',
  },
];

export const getSolarAccountColumns = (handleOpenLinkModal, updateAccess) => {
  let solar_account_columns = [
    {
      dataField: 'name',
      text: 'Meter Name',
    },
    {
      dataField: 'status',
      text: 'Meter Status',
      formatter: (cell) =>
        cell ? cell.charAt(0).toUpperCase() + cell.substr(1).toLowerCase() : '',
    },
    {
      dataField: 'sub_account_id',
      text: 'Linked',
      formatter: fmtBoolExists,
    },
    {
      dataField: 'solar_active',
      text: 'Automation Status',
      formatter: fmtBoolExists,
    },
    {
      dataField: 'id',
      text: 'Settings',
      formatter: (_cell, row) => {
        return updateAccess ? (
          <Button
            size='sm'
            variant='link'
            style={{ width: '100%', margin: '0px', padding: '0px' }}
            onClick={() => handleOpenLinkModal(row)}
          >
            <div style={styles.buttonContainer}>
              <FontAwesomeIcon
                icon={row.sub_account_id ? faEdit : faPlusSquare}
                style={styles.buttonIcon}
              />
              {'Edit'}
            </div>
          </Button>
        ) : (
          ''
        );
      },
    },
  ];

  return solar_account_columns;
};

export const filterSolarAccountByCredential = (credential, account) => {
  return account.solar_provider_id === credential.solar_provider_id;
};

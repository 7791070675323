import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form, InputGroup, Tabs, Tab, Alert } from 'react-bootstrap';
import { isEqual } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import EditEntityActionButtons from '../../../components/forms/EditEntityActionButtons';
import BuildingSelectModal from '../../../components/modals/BuildingSelectModal';
import BillDateSelect from '../../../components/bills/BillFormInputs/BillDateSelect';
import CHARTCOLORS from '../../../helpers/chartColors';
import PopoverTrigger from '../../../components/PopoverTrigger';
import ReportCheckBoxPicker from '../../../components/reports/ReportCheckboxPicker';
import {
  getReportTypes,
  ReportTypesLookup,
  UtilityTypesLookup,
} from '../../Reports/ReportHelpers';
import consumptionUnits from '../../../helpers/consumptionUnits';
import { ghg_units } from '../../../helpers/GHGUnits';
import ProjectUnitsDropdown from './ProjectUnitsDropdown';
import ProjectRegressions from './ProjectRegressions';
import ProjectRates from './ProjectRates';
import ProjectMeasures from './ProjectMeasures';
import ProjectGHGConversions from './ProjectGHGConversions';
import ProjectedSavings from './ProjectedSavings';
import ProjectReportOptions from './ProjectReportOptions';

dayjs.extend(utc);

const DEFAULT_PROJECT = {
  name: '',
  savings_baseline_start_date: dayjs(),
  savings_baseline_start_date_month: '',
  savings_baseline_start_date_year: '',
  savings_start_date: dayjs(),
  savings_start_date_month: '',
  savings_start_date_year: '',
  implementation_start_date: '',
  implementation_start_date_month: '',
  implementation_start_date_year: '',
  ignore_building_ids: [],
  baseline_range_type: 1,
  blended_rate_option: 2,
  savings_option: 0,
  utility_types: [],
  report_types: [],
  project_units: {},
  default_project: false,
  report_options: [],
};

const PAYLOAD_KEYS = [
  'name',
  'ignore_building_ids',
  'baseline_range_type',
  'blended_rate_option',
  'savings_option',
  'utility_types',
  'report_types',
  'project_units',
  'default_project',
  'report_options',
];

const DATE_KEYS = [
  'savings_baseline_start_date',
  'savings_start_date',
  'implementation_start_date',
];

export default function PerformanceProject(props) {
  const {
    showToast,
    organization,
    buildings,
    buildingIds,
    buildingsLookup,
    availableUtilities,
    existingModels,
    rates,
    rateCollections,
    measures,
    GHGConversions,
    GHGCollections,
    addToList,
    updateList,
    project,
    setProjects,
    resetProject,
    removeProject,
    activeGHGLicense,
    updateAccessAnalysis,
    deleteAccessAnalysis,
  } = props;

  const [activeTab, setActiveTab] = useState('report-details');

  const [localProject, setLocalProject] = useState(DEFAULT_PROJECT);
  const [editProject, setEditProject] = useState(DEFAULT_PROJECT);
  const [actionsDisabled, setActionsDisabled] = useState(true);

  const [showBuildingModal, setShowBuildingModal] = useState(false);
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [nonSelectedBuildings, setNonSelectedBuildings] = useState([]);
  const [selectedString, setSelectedString] = useState('');
  const [localProjectBuildings, setLocalProjectBuildings] = useState([]);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let temp_project = { ...project };

    temp_project = handleSavingDates(temp_project);

    setLocalProject({ ...temp_project });
    setEditProject({ ...temp_project });
  }, [project, availableUtilities, activeGHGLicense]);

  const handleSavingDates = (project) => {
    if (project.savings_baseline_start_date) {
      project.savings_baseline_start_date_month = dayjs
        .utc(project.savings_baseline_start_date)
        .month();
      project.savings_baseline_start_date_year = dayjs
        .utc(project.savings_baseline_start_date)
        .year();
    } else {
      project.savings_baseline_start_date_month = null;
      project.savings_baseline_start_date_year = null;
    }
    if (project.savings_start_date) {
      project.savings_start_date_month = dayjs
        .utc(project.savings_start_date)
        .month();
      project.savings_start_date_year = dayjs
        .utc(project.savings_start_date)
        .year();
    } else {
      project.savings_start_date_month = null;
      project.savings_start_date_year = null;
    }
    if (project.implementation_start_date) {
      project.implementation_start_date_month = dayjs
        .utc(project.implementation_start_date)
        .month();
      project.implementation_start_date_year = dayjs
        .utc(project.implementation_start_date)
        .year();
    } else {
      project.implementation_start_date_month = null;
      project.implementation_start_date_year = null;
    }
    return project;
  };

  const setProjectUnits = (project, utilities, default_units) => {
    utilities
      .filter((utility) => utility !== 'electric')
      .forEach((utility) => {
        const key = utility + '_unit';
        project[key] = Object.keys(default_units).includes(utility)
          ? default_units[utility]
          : consumptionUnits[utility][0];
      });
    project['ghg_unit'] = Object.keys(default_units).includes('ghg')
      ? default_units['ghg']
      : 'lbs';
    return project;
  };

  useEffect(() => {
    setActionsDisabled(isEqual(localProject, editProject));
  }, [localProject, editProject]);

  const clearChanges = () => {
    setEditProject({ ...localProject });
  };

  const onFieldChange = useCallback((fieldName, value) => {
    setEditProject((ep) => {
      ep[fieldName] = value;
      return { ...ep };
    });
  }, []);

  const updateUnits = useCallback(
    (utility, value) => {
      onFieldChange(utility + '_unit', value);
    },
    [onFieldChange]
  );

  const setUtilites = useCallback(
    (value) => {
      onFieldChange('utility_types', value);
    },
    [onFieldChange]
  );
  const setReportTypes = useCallback(
    (value) => {
      onFieldChange('report_types', value);
    },
    [onFieldChange]
  );

  useEffect(() => {
    if (
      buildings.length > 0 &&
      localProject &&
      localProject.ignore_building_ids
    ) {
      const temp_ids = buildings.map((building) => {
        return building.id;
      });

      let temp_selected = [];
      let temp_non_selected = [];
      temp_ids.forEach((id) => {
        if (localProject.ignore_building_ids.includes(id)) {
          temp_non_selected.push(id);
        } else {
          temp_selected.push(id);
        }
      });

      setSelectedBuildings(temp_selected);
      setNonSelectedBuildings(temp_non_selected);
      setLocalProjectBuildings(temp_selected);
    } else {
      setSelectedBuildings([]);
      setNonSelectedBuildings([]);
      setLocalProjectBuildings([]);
    }
  }, [buildings, localProject]);

  useEffect(() => {
    onFieldChange('ignore_building_ids', nonSelectedBuildings);
  }, [nonSelectedBuildings, onFieldChange]);

  useEffect(() => {
    let temp_string = '';
    for (let i = 0; i < selectedBuildings.length; i++) {
      temp_string =
        temp_string +
        buildings.find((building) => building.id === selectedBuildings[i]).name;
      if (i !== selectedBuildings.length - 1) {
        temp_string = temp_string + ', ';
      }
    }
    setSelectedString(temp_string);
  }, [selectedBuildings, buildings]);

  const handleChangeDate = (type, data, val) => {
    if (!isNaN(val)) {
      let obj = {};
      if (data === 'year') {
        obj.year = parseInt(val);
        obj.month = editProject[type + '_month'];
      } else {
        obj.year = editProject[type + '_year'];
        obj.month = parseInt(val);
      }

      if (obj.year !== '' && obj.month !== '') {
        obj.value = dayjs.utc(
          obj.year + '-' + (parseInt(obj.month) + 1) + '-01',
          'YYYY-MM-DD'
        );
      } else {
        obj.value = null;
      }
      onFieldChange(type, obj.value);
      onFieldChange(type + '_month', obj.month);
      onFieldChange(type + '_year', obj.year);
    } else {
      onFieldChange(type, null);
      onFieldChange(type + '_month', null);
      onFieldChange(type + '_year', null);
    }
  };

  const checkDatesValid = (start_date, end_date, implementation_date) => {
    let valid_dates = 0;
    if (end_date && start_date) {
      if (start_date.year(start_date.year() + 1) > end_date) {
        valid_dates = 1;
      }
    }
    if (end_date && start_date && implementation_date) {
      if (
        implementation_date > end_date ||
        implementation_date < start_date ||
        start_date.year(start_date.year() + 1) > implementation_date
      ) {
        valid_dates = 2;
      }
    }
    return valid_dates;
  };

  const handleRefreshData = (data, setActive) => {
    if (setActive) {
      data = setProjectUnits(data, data.utility_types, data.project_units);
      setLocalProject(data);
      setEditProject(data);
    }
    setProjects((prev) =>
      prev.map((project) => {
        if (
          !(
            project['id'] === data['id'] &&
            project['project_type'] === data['project_type']
          )
        )
          return project;
        return data;
      })
    );
  };

  const handleDelete = () => {
    const msg = `Are you sure you want to delete this Project? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteProject();
    });
  };

  const deleteProject = () => {
    setIsDeleting(true);
    ApiServiceServerless.delete(
      `/performance_projects/${parseInt(localProject.id)}`,
      { authorization_id: organization.id }
    )
      .then(() => {
        removeProject(localProject.id, localProject.project_type);
        resetProject();
        setIsDeleting(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        setIsDeleting(false);
        throw err;
      });
  };

  const saveProject = (e) => {
    e.preventDefault();
    let valid_date = checkDatesValid(
      dayjs.utc(editProject.savings_baseline_start_date),
      dayjs.utc(editProject.savings_start_date),
      dayjs.utc(editProject.implementation_start_date)
    );
    if (valid_date === 0) {
      setActionsDisabled(true);
      let payload = handlePayload(editProject, localProject);
      setIsSaving(true);
      ApiServiceServerless.put(
        `/performance_projects/${parseInt(localProject.id)}`,
        payload,
        { authorization_id: organization.id }
      )
        .then((res) => {
          handleRefreshData(res.data[0], true);
          res.data[1].forEach((data) => updateList(data, 'models'));

          if (res.data.length > 2) {
            handleRefreshData(res.data[2], false);
          }
          setLocalProjectBuildings(selectedBuildings);
          showToast('success', 'Success', 'Project Saved');
          setActionsDisabled(false);
          setIsSaving(false);
        })
        .catch((err) => {
          setActionsDisabled(false);
          showToast('danger', 'Error', err);
          clearChanges();
          setIsSaving(false);
          throw err;
        });
    } else if (valid_date === 1) {
      showToast(
        'danger',
        'Error',
        'Savings Date must be one year ahead of Baseline Date'
      );
    } else if (valid_date === 2) {
      showToast(
        'danger',
        'Error',
        'Implementation Date must be between 12 months after Baseline Date and before Savings Date'
      );
    }
  };

  const handlePayload = (editProject, localProject) => {
    let project_units = {};
    let utilities = editProject.utility_types.filter(
      (utility) => utility !== 'electric'
    );
    utilities.forEach((utility) => {
      const key = utility + '_unit';
      let unit = editProject[key];
      if (!unit) {
        unit = consumptionUnits[utility][0];
      }
      project_units[utility] = unit;
    });
    if (editProject.ghg_unit) {
      project_units['ghg'] = editProject['ghg_unit'];
    }
    editProject['project_units'] = project_units;

    let payload = {};
    PAYLOAD_KEYS.forEach((key) => {
      if (!isEqual(editProject[key], localProject[key])) {
        payload[key] = editProject[key];
      }
    });
    DATE_KEYS.forEach((key) => {
      if (
        dayjs.utc(editProject[key]).format('DD/MM/YYYY') !==
        dayjs.utc(localProject[key]).format('DD/MM/YYYY')
      ) {
        payload[key] = editProject[key];
      }
    });

    return payload;
  };

  return (
    <>
      {availableUtilities.length === 0 && (
        <Row style={{ justifyContent: 'center' }}>
          <Alert style={{ width: '90%' }} variant='danger'>
            Warning: There are no available utility types for this project. This
            project cannot be completed until utility types become available.
          </Alert>
        </Row>
      )}
      <Tabs
        id='project-regressions-tabs'
        activeKey={activeTab}
        onSelect={setActiveTab}
        className='et-second-tabs'
      >
        <Tab eventKey='report-details' title={'Report Details'}>
          <Row>
            <Col md={2}></Col>
            <Col md={8}>
              <Form noValidate onSubmit={saveProject}>
                <Form.Group controlId='project.name'>
                  <Form.Label>
                    Project Name
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    required
                    value={editProject.name || ''}
                    disabled={!updateAccessAnalysis}
                    onChange={(e) => onFieldChange('name', e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId='project.defaultProject'>
                  <Form.Label>Set as default project?</Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) =>
                      onFieldChange('default_project', e.target.value)
                    }
                    value={editProject.default_project || ''}
                  >
                    <option key={'false'} value={false}>
                      No
                    </option>
                    <option key={'true'} value={true}>
                      Yes
                    </option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId='project.buildings'>
                  <Form.Label>
                    Select Buildings
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    required
                    value={
                      buildings.length === selectedBuildings.length
                        ? 'All Buildings'
                        : selectedString
                    }
                    disabled={true}
                    style={{ paddingRight: '5%' }}
                  />
                  <FontAwesomeIcon
                    style={{
                      fontSize: '20px',
                      marginLeft: '0.5em',
                      color: CHARTCOLORS[1],
                      cursor: 'pointer',
                      float: 'right',
                      marginTop: '-29px',
                      marginRight: '5px',
                    }}
                    icon={faEdit}
                    id={`report-building-select-popover`}
                    onClick={() => setShowBuildingModal(true)}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <ReportCheckBoxPicker
                      label={'Utility Types'}
                      checkboxOptions={availableUtilities}
                      checkboxLookups={UtilityTypesLookup}
                      defaults={localProject.utility_types}
                      requireds={[]}
                      updateLocal={setUtilites}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ReportCheckBoxPicker
                      label={'Report Types'}
                      checkboxOptions={getReportTypes(activeGHGLicense)}
                      checkboxLookups={ReportTypesLookup}
                      defaults={localProject.report_types}
                      requireds={[]}
                      updateLocal={setReportTypes}
                    />
                  </Col>
                </Row>
                <Row>
                  {editProject.utility_types
                    .filter((utility) => utility !== 'electric')
                    .map((utility) => (
                      <Col key={utility}>
                        <ProjectUnitsDropdown
                          updateUnits={updateUnits}
                          unit_key={utility}
                          title={
                            utility.charAt(0).toUpperCase() +
                            utility.substr(1).toLowerCase()
                          }
                          units={consumptionUnits[utility]}
                          names={consumptionUnits[utility]}
                          defaultUnit={
                            editProject['project_units'] &&
                            editProject['project_units'][utility]
                              ? editProject['project_units'][utility]
                              : null
                          }
                        />
                      </Col>
                    ))}
                </Row>
                <Row>
                  {editProject.report_types.includes('ghg') && (
                    <Col key={'ghg'}>
                      <ProjectUnitsDropdown
                        updateUnits={updateUnits}
                        unit_key={'ghg'}
                        title={'GHG Emissions'}
                        units={Object.keys(ghg_units)}
                        names={Object.values(ghg_units)}
                        defaultUnit={
                          editProject['project_units'] &&
                          editProject['project_units']['ghg']
                            ? editProject['project_units']['ghg']
                            : null
                        }
                      />
                    </Col>
                  )}
                </Row>

                <Form.Group controlId='project.BaselineStartDate'>
                  <Form.Label>Baseline start date</Form.Label>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <InputGroup size='sm'>
                        <BillDateSelect
                          type='year'
                          value={
                            editProject['savings_baseline_start_date_year']
                          }
                          onChange={(e) =>
                            handleChangeDate(
                              'savings_baseline_start_date',
                              'year',
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <InputGroup size='sm'>
                        <BillDateSelect
                          type='month'
                          value={
                            editProject['savings_baseline_start_date_month']
                          }
                          onChange={(e) =>
                            handleChangeDate(
                              'savings_baseline_start_date',
                              'month',
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
                <Form.Group controlId='project.SavingsStartDate'>
                  <Form.Label>Project savings start date</Form.Label>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <InputGroup size='sm'>
                        <BillDateSelect
                          type='year'
                          value={editProject['savings_start_date_year']}
                          onChange={(e) =>
                            handleChangeDate(
                              'savings_start_date',
                              'year',
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <InputGroup size='sm'>
                        <BillDateSelect
                          type='month'
                          value={editProject['savings_start_date_month']}
                          onChange={(e) =>
                            handleChangeDate(
                              'savings_start_date',
                              'month',
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
                <Form.Group controlId='project.ImplementationStartDate'>
                  <Form.Label>Project implementation start date</Form.Label>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <InputGroup size='sm'>
                        <BillDateSelect
                          type='year'
                          value={editProject['implementation_start_date_year']}
                          onChange={(e) =>
                            handleChangeDate(
                              'implementation_start_date',
                              'year',
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <InputGroup size='sm'>
                        <BillDateSelect
                          type='month'
                          value={editProject['implementation_start_date_month']}
                          onChange={(e) =>
                            handleChangeDate(
                              'implementation_start_date',
                              'month',
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
                <EditEntityActionButtons
                  onSubmit={saveProject}
                  onCancel={clearChanges}
                  onDelete={handleDelete}
                  submitDisabled={actionsDisabled}
                  cancelDisabled={actionsDisabled}
                  submitShow={updateAccessAnalysis}
                  deleteShow={deleteAccessAnalysis}
                  isSubmitting={isSaving}
                  isDeleting={isDeleting}
                />
              </Form>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey='savings-details' title={'Savings Details'}>
          <Row>
            <Col md={2}></Col>
            <Col md={8}>
              <Form noValidate onSubmit={saveProject}>
                <PerformanceSavingsInfo />
                <Form.Group controlId='project.savingsOption'>
                  <Form.Label>Savings Option</Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) =>
                      onFieldChange('savings_option', e.target.value)
                    }
                    value={editProject.savings_option || ''}
                  >
                    <option key={'savings_type_0'} value={0}>
                      Performance Bill Savings
                    </option>
                    <option key={'savinga_type_1'} value={1}>
                      Normalized Rate Savings
                    </option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId='project.blendedRateOption'>
                  <Form.Label>Blended Rate Option</Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) =>
                      onFieldChange('blended_rate_option', e.target.value)
                    }
                    value={editProject.blended_rate_option || ''}
                  >
                    <option key={'range_type_0'} value={0}>
                      Baseline Rate
                    </option>
                    <option key={'range_type_1'} value={1}>
                      Performance Rate
                    </option>
                    <option key={'range_type_2'} value={2}>
                      Maximum Rate
                    </option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId='project.baselineRangeType'>
                  <Form.Label>Blended Rate Range</Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) =>
                      onFieldChange('baseline_range_type', e.target.value)
                    }
                    value={editProject.baseline_range_type || ''}
                  >
                    <option key={'range_type_0'} value={0}>
                      Organization Baseline Last Bill (Not Recommended)
                    </option>
                    <option key={'range_type_1'} value={1}>
                      Organization Baseline Range
                    </option>
                    <option key={'range_type_2'} value={2}>
                      Regression Model Range
                    </option>
                  </Form.Control>
                </Form.Group>

                <EditEntityActionButtons
                  onSubmit={saveProject}
                  onCancel={clearChanges}
                  submitDisabled={actionsDisabled}
                  cancelDisabled={actionsDisabled}
                  submitShow={updateAccessAnalysis}
                  deleteShow={false}
                  showToast={showToast}
                  isSubmitting={isSaving}
                  isDeleting={isDeleting}
                />
              </Form>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={2}></Col>
            <Col md={8}>
              <br></br>
              <ProjectedSavings
                organizationId={organization.id}
                project={project}
                handleRefreshData={handleRefreshData}
                updateAccessAnalysis={updateAccessAnalysis}
                showToast={showToast}
              />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey='report-options' title={'Report Options'}>
          <ProjectReportOptions
            localProject={localProject}
            saveProject={saveProject}
            clearChanges={clearChanges}
            onFieldChange={onFieldChange}
            actionsDisabled={actionsDisabled}
            updateAccessAnalysis={updateAccessAnalysis}
            showToast={showToast}
            isSaving={isSaving}
          />
        </Tab>
        <Tab eventKey='project-regression' title={'Regressions'}>
          <ProjectRegressions
            organization_id={organization.id}
            project_id={localProject['id']}
            buildingsLookup={buildingsLookup}
            selectedBuildings={localProjectBuildings}
            utility_types={localProject['utility_types'].filter((utility) =>
              ['electric', 'gas'].includes(utility)
            )}
            existingModels={existingModels}
            updateList={updateList}
            showToast={showToast}
            updateAccessAnalysis={updateAccessAnalysis}
            deleteAccessAnalysis={deleteAccessAnalysis}
          />
        </Tab>
        <Tab eventKey='project-rate' title={'Rates'}>
          <ProjectRates
            organization_id={organization.id}
            project_id={localProject['id']}
            buildingsLookup={buildingsLookup}
            selectedBuildings={localProjectBuildings}
            utility_types={localProject['utility_types']}
            rates={rates}
            rateCollections={rateCollections}
            updateList={updateList}
            showToast={showToast}
            updateAccessAnalysis={updateAccessAnalysis}
            deleteAccessAnalysis={deleteAccessAnalysis}
          />
        </Tab>
        <Tab eventKey='project-measure' title={'Operational Savings'}>
          <ProjectMeasures
            organization_id={organization.id}
            project={localProject}
            measures={measures}
            updateList={updateList}
            addToList={addToList}
            showToast={showToast}
            updateAccessAnalysis={updateAccessAnalysis}
            deleteAccessAnalysis={deleteAccessAnalysis}
          />
        </Tab>
        <Tab eventKey='project-ghg-conversions' title={'GHG Conversions'}>
          <ProjectGHGConversions
            organization_id={organization.id}
            project_id={localProject['id']}
            buildingsLookup={buildingsLookup}
            selectedBuildings={localProjectBuildings}
            GHGConversions={GHGConversions}
            GHGCollections={GHGCollections}
            updateList={updateList}
            showToast={showToast}
            updateAccessAnalysis={updateAccessAnalysis}
            deleteAccessAnalysis={deleteAccessAnalysis}
          />
        </Tab>
      </Tabs>
      <BuildingSelectModal
        show={showBuildingModal}
        onHide={() => setShowBuildingModal(false)}
        buildings={buildings}
        buildingIds={buildingIds}
        selectedBuildings={selectedBuildings}
        setSelectedBuildings={setSelectedBuildings}
        setNonSelectedBuildings={setNonSelectedBuildings}
      />
      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
}

function PerformanceSavingsInfo() {
  return (
    <PopoverTrigger
      rootClose={true}
      popoverTitle={'Performance Savings Options'}
      popoverContent={
        <div>
          <div>
            <b>Performance Bill Savings -</b> Savings = Weather Normalized Usage
            * Rate - Actual Cost
          </div>{' '}
          <div>
            <b>Normalized Rate Savings -</b> Savings = (Weather Normalized Usage
            - Actual Usage) *Rate
          </div>{' '}
        </div>
      }
      trigger={'click'}
      placement='right'
    >
      <FontAwesomeIcon
        style={{
          fontSize: '18px',
          color: CHARTCOLORS[1],
          marginLeft: '5px',
          cursor: 'pointer',
          position: 'absolute',
          left: '125px',
        }}
        icon={faInfoCircle}
      />
    </PopoverTrigger>
  );
}

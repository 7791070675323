export const DEFAULT_BUILDING_VALUES = {
  id: null,
  name: '',
  square_footage: '',
  address: {},
  not_physical: '',
  organization_id: null,
  reference_id: '',
  organization: { name: '' },
  utility_acconts: [],
  has_provider_account: false,
  building_type: null,
  benchmark_type: null,
  accounts: [],
  siblings: [],
};

export const DEFAULT_ACCOUNT_VALUES = {
  id: null,
  account_number: '',
  exclude_from_reports: '',
  account_type: '',
  organization: { id: null },
  utility_Provider: '',
  reference_id: '',
  reference_id2: '',
  sub_accounts: [],
  buildings: [],
  physical_buildings: [],
  available_masks: {},
  child_masks: [],
  parent_masks: [],
  parent_mask_sub_accounts: [],
  utility_template: {},
};

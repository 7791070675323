import React, { useCallback, useEffect, useState } from 'react';
import { Card, Container, Col, Row, Alert } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUtilityPole,
  faEnvelope,
  faBolt,
  faBoltSlash,
  faEye,
  faEyeSlash,
  faSyncAlt,
  faUpload,
  faFileSpreadsheet,
} from '@fortawesome/pro-solid-svg-icons';

import Loader from '../../components/Loader';

import NewProviderModal from '../../components/modals/ProviderModals/NewProviderModal';
import UrjanetLinkAccountModal from '../../components/modals/UrjanetLinkAccountModal';
import PlugLinkAccountModal from '../../components/modals/PlugLinkAccountModal';
import PlugUploadModal from '../../components/modals/PlugUploadModal';
import PlugDownloadModal from '../../components/modals/PlugDownloadModal';
import UtilityTable from './UtilityTable';
import RequestCredentialModal from '../../components/modals/ProviderModals/RequestCredentialModal';
import { useOrganizationRoles } from '../../helpers/checkRoles';
import {
  getTitleName,
  handleGetLicenseKey,
  handleGetAllCredentials,
  handleRefreshAccountsData,
  handleRefreshLocalData,
  handleJoinLocalAccounts,
} from './helpers/provider';

function UtilityImportSettings(props) {
  const {
    organization,
    userSelectedOrganizationDetails,
    provider_type,
    OPAUpdate,
    visible,
    handleUpdateTables,
    showToast,
  } = props;

  // state variables
  const [allCredentials, setAllCredentials] = useState([]);
  const [pseudoCredentials, setPseudoCredentials] = useState([]);
  const [credentialIds, setCredentialIds] = useState([]);
  const [providerAccounts, setProviderAccounts] = useState([]);
  const [providerAccountsWithLocal, setProviderAccountsWithLocal] = useState(
    []
  );
  const [localUtilityAccounts, setLocalUtilityAccounts] = useState([]);
  const [localBuildings, setLocalBuildings] = useState([]);
  const [posr, setposr] = useState(null); //provider organization share recipient
  const [hiddenCredentials, setHiddenCredentials] = useState(null);

  //state flags
  const [showSecondary, setShowSecondary] = useState(false);
  const [showHidden, setShowHidden] = useState(false);

  //modal flags
  const [showNewProvider, setShowNewProvider] = useState(false);
  const [showUrjanetLinkModal, setShowUrjanetLinkModal] = useState(false);
  const [showPlugLinkModal, setShowPlugLinkModal] = useState(false);
  const [showRequestCredentialModal, setShowRequestCredentialModal] =
    useState(false);
  const [showPlugUploadModal, setShowPlugUploadModal] = useState(false);
  const [showPlugDownloadModal, setShowPlugDownloadModal] = useState(false);

  //licenses, loading, access
  const [activeLicense, setActiveLicense] = useState(false);
  const [isLoadingCreds, setIsLoadingCreds] = useState(true);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);

  const [lastOPAUpdate, setLastOPAUpdate] = useState(null);
  const [firstUpdate, setFirstUpdate] = useState(0);
  const [manualRefresh, setManualRefresh] = useState(0);

  const updateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'organization',
    'update'
  );

  useEffect(() => {
    let activeLicense = false;
    let license_key = handleGetLicenseKey(provider_type);
    if (organization.id) {
      if (
        organization &&
        organization.licenses &&
        license_key in organization.licenses
      ) {
        activeLicense = organization.licenses[license_key];
      }
    }
    setActiveLicense(activeLicense);
  }, [organization, provider_type]);

  useEffect(() => {
    if (organization.id) {
      setposr(organization.posr);
      setHiddenCredentials(organization.hidden_provider_credentials);
    }
  }, [
    organization.posr,
    organization.id,
    organization.hidden_provider_credentials,
  ]);

  // modal data
  const [urjanetLinkModalData, setUrjanetLinkModalData] = useState({
    id: null,
    enrolled: false,
    linkedAccount: null,
    accountNumber: '',
    utilityProvider: '',
    subscribe: true,
    pullHistory: true,
  });
  const [plugLinkModalData, setPlugLinkModalData] = useState({
    id: '',
    linkedAccount: '',
    accountNumber: '',
    utilityProvider: '',
    active: true,
    credentialId: null,
    localAccount: { id: '' },
  });

  // modal functions
  const handleCloseNewProviderModal = () => {
    setShowNewProvider(false);
    getAllCredentials();
  };

  const handleOpenLinkModal = (selectedRow, provider_type) => {
    let local_row = { ...selectedRow };
    local_row.newAccountId = local_row.localAccount
      ? local_row.localAccount.id
      : null;
    if (provider_type === 1) {
      setUrjanetLinkModalData(local_row);
      setShowUrjanetLinkModal(true);
    }
    if (provider_type === 2) {
      setPlugLinkModalData(local_row);
      setShowPlugLinkModal(true);
    }
  };

  //get all credentials functions
  const getAllCredentials = useCallback(() => {
    if (organization.id && posr && hiddenCredentials) {
      handleGetAllCredentials(
        setAllCredentials,
        setCredentialIds,
        organization.id,
        posr,
        hiddenCredentials,
        provider_type,
        setIsLoadingCreds,
        showToast
      );
    }
  }, [organization, posr, hiddenCredentials, showToast, provider_type]);

  useEffect(() => {
    getAllCredentials();
  }, [getAllCredentials]);

  // get account functions
  const refreshAccountsData = useCallback(() => {
    handleRefreshAccountsData(
      setPseudoCredentials,
      setProviderAccounts,
      setLocalUtilityAccounts,
      setLocalBuildings,
      organization.id,
      provider_type,
      credentialIds,
      setIsLoadingAccounts,
      showToast
    );
  }, [organization, provider_type, credentialIds, showToast]);

  useEffect(() => {
    handleJoinLocalAccounts(
      providerAccounts,
      localUtilityAccounts,
      provider_type,
      setProviderAccountsWithLocal
    );
  }, [providerAccounts, localUtilityAccounts, provider_type]);

  useEffect(() => {
    refreshAccountsData();
    setFirstUpdate(true);
  }, [refreshAccountsData]);

  useEffect(() => {
    if (
      firstUpdate &&
      OPAUpdate !== lastOPAUpdate &&
      visible &&
      organization.id
    ) {
      handleRefreshLocalData(
        setLocalUtilityAccounts,
        setLocalBuildings,
        organization.id,
        showToast
      );
      setLastOPAUpdate(OPAUpdate);
    }
    if (!firstUpdate) {
      setLastOPAUpdate(OPAUpdate);
    }
  }, [
    OPAUpdate,
    lastOPAUpdate,
    visible,
    firstUpdate,
    organization.id,
    showToast,
  ]);

  useEffect(() => {
    if (manualRefresh > 0) {
      getAllCredentials();
      refreshAccountsData();
      setManualRefresh(0);
    }
  }, [manualRefresh, refreshAccountsData, getAllCredentials]);

  const getStyles = (disabled) => {
    return {
      iconStyle: {
        fontSize: '20px',
        margin: '0 0.35em',
        verticalAlign: 'text-bottom',
        cursor: disabled ? 'none' : 'pointer',
        color: 'var(--et_tab_grey)',
        opacity: disabled ? '0.5' : '1.0',
      },
    };
  };

  return (
    <Card.Body>
      {!activeLicense && (
        <Alert style={{ width: '90%' }} variant='danger'>
          Warning: This license is expired. Please contact an administrator
          about renewing this license.
        </Alert>
      )}
      <Row>
        <Col style={{ marginBottom: '0.5em' }} xs={12}>
          <h5 style={{ marginBottom: 0 }}>
            {'Manage ' + getTitleName(provider_type) + ' Accounts'}
          </h5>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: '12px',
            }}
          >
            {updateAccess && activeLicense && (
              <div style={{ textAlign: 'right' }}>
                {provider_type === 2 && (
                  <FontAwesomeIcon
                    style={getStyles(isLoadingCreds).iconStyle}
                    icon={faFileSpreadsheet}
                    title={'Download Data'}
                    disabled={isLoadingCreds}
                    onClick={() => setShowPlugDownloadModal(true)}
                  />
                )}
                {provider_type === 2 && (
                  <FontAwesomeIcon
                    style={getStyles(isLoadingCreds).iconStyle}
                    icon={faUpload}
                    title={'Upload Files'}
                    disabled={isLoadingCreds}
                    onClick={() => setShowPlugUploadModal(true)}
                  />
                )}
                <FontAwesomeIcon
                  style={getStyles(isLoadingCreds).iconStyle}
                  icon={faEnvelope}
                  title={'Request Credentials'}
                  disabled={isLoadingCreds}
                  onClick={() => setShowRequestCredentialModal(true)}
                />
                <FontAwesomeIcon
                  style={getStyles(isLoadingCreds).iconStyle}
                  icon={faUtilityPole}
                  title={'Add New Provider'}
                  disabled={isLoadingCreds}
                  onClick={() => setShowNewProvider(true)}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            style={{
              textAlign: 'right',
              paddingBottom: '0.5em',
            }}
          >
            <FontAwesomeIcon
              style={getStyles(isLoadingCreds).iconStyle}
              icon={faSyncAlt}
              title={'Refresh Data'}
              disabled={isLoadingCreds}
              onClick={() => setManualRefresh(1)}
            />
            <FontAwesomeIcon
              style={getStyles(isLoadingCreds).iconStyle}
              icon={showHidden ? faEyeSlash : faEye}
              title={'Show Hidden Credentials'}
              disabled={isLoadingCreds}
              onClick={() => setShowHidden((prev) => !prev)}
            />
            <FontAwesomeIcon
              style={getStyles(isLoadingCreds).iconStyle}
              icon={showSecondary ? faBoltSlash : faBolt}
              title={'Show Secondary Account'}
              disabled={isLoadingCreds}
              onClick={() => setShowSecondary((prev) => !prev)}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          {isLoadingCreds ? (
            <Container>
              <Row>
                <Col
                  style={{
                    paddingBottom: '10vh',
                    paddingTop: '5vh',
                    paddingLeft: '49%',
                  }}
                >
                  <Loader />
                </Col>
              </Row>
            </Container>
          ) : (
            <UtilityTable
              organizationId={organization.id}
              allCredentials={allCredentials}
              pseudoCredentials={pseudoCredentials}
              providerAccounts={providerAccountsWithLocal}
              provider_type={provider_type}
              showSecondary={showSecondary}
              showHidden={showHidden}
              handleOpenLinkModal={handleOpenLinkModal}
              isLoading={isLoadingAccounts}
              setAllCredentials={setAllCredentials}
              showToast={showToast}
              updateAccess={updateAccess}
              activeLicense={activeLicense}
            />
          )}
        </Col>
      </Row>
      <NewProviderModal
        organizationId={organization.id}
        show={showNewProvider}
        onHide={handleCloseNewProviderModal}
        provider_type={provider_type}
      />
      <UrjanetLinkAccountModal
        show={showUrjanetLinkModal}
        onHide={() => setShowUrjanetLinkModal(false)}
        modalData={urjanetLinkModalData}
        localUtilityAccounts={localUtilityAccounts}
        organization={organization}
        buildings={localBuildings}
        provider_type={1}
        setProviderAccounts={setProviderAccounts}
        setLocalUtilityAccounts={setLocalUtilityAccounts}
        handleUpdateTables={handleUpdateTables}
        showToast={showToast}
        activeLicense={activeLicense}
      />
      <PlugLinkAccountModal
        show={showPlugLinkModal}
        onHide={() => setShowPlugLinkModal(false)}
        modalData={plugLinkModalData}
        localUtilityAccounts={localUtilityAccounts}
        organization={organization}
        buildings={localBuildings}
        provider_type={2}
        setProviderAccounts={setProviderAccounts}
        setLocalUtilityAccounts={setLocalUtilityAccounts}
        handleUpdateTables={handleUpdateTables}
        showToast={showToast}
        activeLicense={activeLicense}
      />
      <RequestCredentialModal
        show={showRequestCredentialModal}
        onHide={() => setShowRequestCredentialModal(false)}
        organizationId={organization.id}
        showToast={showToast}
        variant='new'
        provider_type={provider_type}
      />
      <PlugUploadModal
        show={showPlugUploadModal}
        onHide={() => {
          setShowPlugUploadModal(false);
        }}
        organizationId={organization.id}
      />
      <PlugDownloadModal
        show={showPlugDownloadModal}
        onHide={() => {
          setShowPlugDownloadModal(false);
        }}
        organizationId={organization.id}
        providerAccounts={providerAccounts}
      />
    </Card.Body>
  );
}

export default UtilityImportSettings;

import React, { useState, useCallback, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import UtilityImportSettings from '../../../components/UtilityImportSettings';
import SolarUtilityImportSettings from '../../../components/UtilityImportSettings/SolarUtilityImportSettings';
import PortfolioManager from '../../../components/PortfolioManager';

const ordered_licenses = ['urjanet', 'plug', 'portfolio_manager', 'solar'];

export default function OrganizationConnections(props) {
  const {
    organization,
    userSelectedOrganizationDetails,
    showToast,
    OPAUpdate,
    visible,
    handleUpdateTables,
  } = props;

  const [activeTab, setActiveTab] = useState('');
  const [activeTabList, setActiveTabList] = useState([]);

  const addNewActiveTab = useCallback(
    (tab) => {
      if (tab !== activeTab) {
        setActiveTab(tab);
      }
      if (!activeTabList.includes(tab)) {
        let temp_keys = [...activeTabList];
        temp_keys.push(tab);
        setActiveTabList(temp_keys);
      }
    },
    [activeTab, activeTabList, setActiveTab]
  );

  const [licenses, setLicenses] = useState([]);

  useEffect(() => {
    let temp_arr = [];
    if (userSelectedOrganizationDetails.licenses) {
      if (userSelectedOrganizationDetails.licenses.urjanet !== null) {
        temp_arr.push('urjanet');
      }
      if (userSelectedOrganizationDetails.licenses.plug !== null) {
        temp_arr.push('plug');
      }
      if (
        userSelectedOrganizationDetails.licenses.solar &&
        userSelectedOrganizationDetails.licenses.solar.status !== 'none'
      ) {
        temp_arr.push('solar');
      }
      if (userSelectedOrganizationDetails.licenses.portfolio_manager !== null) {
        temp_arr.push('portfolio_manager');
      }
    }
    setLicenses(temp_arr);
  }, [userSelectedOrganizationDetails]);

  useEffect(() => {
    ordered_licenses.some((license) => {
      if (licenses.includes(license)) {
        setActiveTab(license);
        setActiveTabList([license]);
        return true;
      }
      return false;
    });
  }, [licenses]);

  return (
    <>
      {licenses.length === 0 ? (
        <>
          No available data connection licenses. Please contact an administrator
          about licensing.
        </>
      ) : (
        <Tabs
          id='provider-tabs'
          activeKey={activeTab}
          onSelect={(e) => addNewActiveTab(e)}
          className='et-second-tabs'
          style={{ paddingLeft: '1em' }}
        >
          {licenses.includes('urjanet') && (
            <Tab eventKey='urjanet' title='Urjanet'>
              {activeTabList.includes('urjanet') && (
                <UtilityImportSettings
                  organization={organization}
                  userSelectedOrganizationDetails={
                    userSelectedOrganizationDetails
                  }
                  provider_type={1}
                  OPAUpdate={OPAUpdate}
                  visible={visible && activeTab === 'urjanet'}
                  handleUpdateTables={handleUpdateTables}
                  showToast={showToast}
                />
              )}
            </Tab>
          )}
          {licenses.includes('plug') && (
            <Tab eventKey='plug' title='Plug'>
              {activeTabList.includes('plug') && (
                <UtilityImportSettings
                  organization={organization}
                  userSelectedOrganizationDetails={
                    userSelectedOrganizationDetails
                  }
                  provider_type={2}
                  OPAUpdate={OPAUpdate}
                  visible={visible && activeTab === 'plug'}
                  handleUpdateTables={handleUpdateTables}
                  showToast={showToast}
                />
              )}
            </Tab>
          )}
          {licenses.includes('solar') && (
            <Tab eventKey='solar' title='Solar'>
              {activeTabList.includes('solar') && (
                <SolarUtilityImportSettings
                  organization={organization}
                  provider_type={3}
                  showToast={showToast}
                />
              )}
            </Tab>
          )}
          {licenses.includes('portfolio_manager') && (
            <Tab eventKey='portfolio_manager' title='Portfolio Manager'>
              {activeTabList.includes('portfolio_manager') && (
                <PortfolioManager
                  organization={organization}
                  userSelectedOrganizationDetails={
                    userSelectedOrganizationDetails
                  }
                  showToast={showToast}
                />
              )}
            </Tab>
          )}
        </Tabs>
      )}
    </>
  );
}

import { cloneDeep, sortBy } from 'lodash';

import { ApiServiceServerless } from '../../../xhr_libs';

import {
  getUrjanetCredentials,
  URJANET_CREDENTIAL_COLUMNS,
  getUrjanetAccountColumns,
  getUrjanetAccounts,
  joinUrjanetAccountsToLocal,
  updateUrjanetUsername,
  updateUrjanetPassword,
  enrollUrjanetAccount,
  updateUrjanetAccount,
  filterUrjanetAccountByCredential,
  handleSetNewUrjanetAccount,
} from './urjanet';
import {
  getPlugCredentials,
  PLUG_CREDENTIAL_COLUMNS,
  getPlugAccountColumns,
  getPlugAccounts,
  joinPlugAccountsToLocal,
  updatePlugUsername,
  updatePlugPassword,
  updatePlugAccount,
  filterPlugAccountByCredential,
  handleSetNewPlugAccount,
} from './plug';
import {
  SOLAR_CREDENTIAL_COLUMNS,
  getSolarAccountColumns,
  filterSolarAccountByCredential,
} from './solar';

export const getTitleName = (provider_type) => {
  if (provider_type === 1) {
    return 'Urjanet';
  }
  if (provider_type === 2) {
    return 'Plug';
  }
  if (provider_type === 3) {
    return 'Solar';
  }

  return '';
};

export const handleGetLicenseKey = (provider_type) => {
  if (provider_type === 1) {
    return 'urjanet';
  } else if (provider_type === 2) {
    return 'plug';
  }
  return '';
};

export const updateCredentialState = (setter, id, property, newValue) => {
  setter((prev) =>
    prev.map((cred) => {
      if (id !== cred.credentialId) return cred;
      let updatedCred = cloneDeep(cred);
      updatedCred[property] = newValue;
      return updatedCred;
    })
  );
};

export const handleGetAllCredentials = (
  setAllCredentials,
  setCredentialIds,
  organizationId,
  posr,
  hiddenCredentials,
  provider_type,
  setIsLoading,
  showToast
) => {
  setIsLoading(true);
  if (organizationId) {
    const shared_orgs = getSharedOrgs(posr, provider_type);
    let orgs = [];
    orgs.push(organizationId);
    shared_orgs.forEach((org) => orgs.push(org));

    let localHiddenCredentialIds = [];
    let hidden_crednentials =
      hiddenCredentials.filter(
        (cred) => cred.provider_type === provider_type
      ) || [];
    localHiddenCredentialIds = hidden_crednentials.map(
      (cred) => cred.credential
    );

    if (provider_type === 1) {
      getUrjanetCredentials(
        setAllCredentials,
        setCredentialIds,
        organizationId,
        orgs,
        localHiddenCredentialIds,
        setIsLoading,
        showToast
      );
    } else if (provider_type === 2) {
      getPlugCredentials(
        setAllCredentials,
        setCredentialIds,
        organizationId,
        orgs,
        localHiddenCredentialIds,
        setIsLoading,
        showToast
      );
    }
  }
};

const getSharedOrgs = (provider_shares, provider_type) => {
  if (!provider_shares) {
    return [];
  }
  return provider_shares
    .filter((share) => share.provider_type === provider_type)
    .map((share) => {
      return share.owner_id;
    });
};

export const getCredentialColumns = (provider_type, setCredentialColumns) => {
  if (provider_type === 1) {
    setCredentialColumns(URJANET_CREDENTIAL_COLUMNS);
  } else if (provider_type === 2) {
    setCredentialColumns(PLUG_CREDENTIAL_COLUMNS);
  } else if (provider_type === 3) {
    setCredentialColumns(SOLAR_CREDENTIAL_COLUMNS);
  } else {
    setCredentialColumns([]);
  }
};

export const getAccountColumns = (
  provider_type,
  setAccountColumns,
  handleOpenLinkModal,
  updateAccess
) => {
  if (provider_type === 1) {
    setAccountColumns(
      getUrjanetAccountColumns(handleOpenLinkModal, updateAccess)
    );
  } else if (provider_type === 2) {
    setAccountColumns(getPlugAccountColumns(handleOpenLinkModal, updateAccess));
  } else if (provider_type === 3) {
    setAccountColumns(
      getSolarAccountColumns(handleOpenLinkModal, updateAccess)
    );
  } else {
    setAccountColumns([]);
  }
};

export const handleRefreshAccountsData = (
  setPseudoCredentials,
  setProviderAccounts,
  setLocalUtilityAccounts,
  setLocalBuildings,
  organizationId,
  provider_type,
  credentialIds,
  setIsLoading,
  showToast
) => {
  setIsLoading(true);
  setProviderAccounts([]);

  if (organizationId) {
    if (credentialIds.length) {
      if (provider_type === 1) {
        getUrjanetAccounts(
          setProviderAccounts,
          organizationId,
          credentialIds,
          showToast,
          setIsLoading
        );
      } else if (provider_type === 2) {
        getPlugAccounts(
          setPseudoCredentials,
          setProviderAccounts,
          organizationId,
          credentialIds,
          showToast,
          setIsLoading
        );
      }
      handleRefreshLocalData(
        setLocalUtilityAccounts,
        setLocalBuildings,
        organizationId
      );
    } else {
      setIsLoading(false);
    }
  }
};

export const handleRefreshLocalData = (
  setLocalUtilityAccounts,
  setLocalBuildings,
  organizationId,
  showToast
) => {
  const getLocalUtilityAccounts = () => {
    ApiServiceServerless.get(
      `/utility_accounts/organization/${organizationId}`,
      { authorization_id: organizationId }
    )
      .then(({ data }) => {
        setLocalUtilityAccounts(sortBy(data, 'account_number'));
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  const getLocalBuildings = () => {
    ApiServiceServerless.get(`/buildings/organization/${organizationId}`, {
      authorization_id: organizationId,
    })
      .then(({ data }) => {
        setLocalBuildings(sortBy(data, 'name'));
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      });
  };
  getLocalUtilityAccounts();
  getLocalBuildings();
};

export const handleJoinLocalAccounts = (
  providerAccounts,
  localUtilityAccounts,
  provider_type,
  setProviderAccountsWithLocal
) => {
  if (provider_type === 1) {
    joinUrjanetAccountsToLocal(
      providerAccounts,
      localUtilityAccounts,
      setProviderAccountsWithLocal
    );
  } else if (provider_type === 2) {
    joinPlugAccountsToLocal(
      providerAccounts,
      localUtilityAccounts,
      setProviderAccountsWithLocal
    );
  } else {
    setProviderAccountsWithLocal([]);
  }
};

export const handleUpdateUsername = (
  provider_type,
  params,
  credential,
  setAllCredentials,
  showToast
) => {
  if (provider_type === 1) {
    updateUrjanetUsername(
      params,
      credential,
      updateCredentialState,
      setAllCredentials,
      showToast
    );
  } else if (provider_type === 2) {
    updatePlugUsername(
      params,
      credential,
      updateCredentialState,
      setAllCredentials,
      showToast
    );
  }
};

export const handleUpdatePassword = (provider_type, params, showToast) => {
  if (provider_type === 1) {
    updateUrjanetPassword(params, showToast);
  } else if (provider_type === 2) {
    updatePlugPassword(params, showToast);
  }
};

export const handleSetAccountKey = (provider_type, setAccountKey) => {
  if (provider_type === 1) {
    setAccountKey('urjanet_account_id');
  } else if (provider_type === 2) {
    setAccountKey('plug_account_id');
  } else {
    setAccountKey('');
  }
};

export const handleEnrollAccount = (
  provider_type,
  modalData,
  organizationId,
  data,
  newAccountId,
  setProviderAccounts,
  setLocalUtilityAccounts,
  setSubmitDisabled,
  showToast,
  onHide,
  setIsSaving
) => {
  if (provider_type === 1) {
    enrollUrjanetAccount(
      modalData,
      organizationId,
      data,
      newAccountId,
      setProviderAccounts,
      setLocalUtilityAccounts,
      setSubmitDisabled,
      showToast,
      onHide,
      setIsSaving
    );
  }
};

export const handleUpdateAccount = (
  provider_type,
  modalData,
  organizationId,
  data,
  newAccountId,
  setProviderAccounts,
  setLocalUtilityAccounts,
  setSubmitDisabled,
  showToast,
  onHide,
  setIsSaving
) => {
  if (provider_type === 1) {
    updateUrjanetAccount(
      modalData,
      organizationId,
      data,
      newAccountId,
      setProviderAccounts,
      setLocalUtilityAccounts,
      setSubmitDisabled,
      showToast,
      onHide,
      setIsSaving
    );
  } else if (provider_type === 2) {
    updatePlugAccount(
      modalData,
      organizationId,
      data,
      newAccountId,
      setProviderAccounts,
      setLocalUtilityAccounts,
      setSubmitDisabled,
      showToast,
      onHide,
      setIsSaving
    );
  }
};

export const handleFilterAccountByCredential = (
  provider_type,
  credential,
  account,
  showSecondary
) => {
  if (provider_type === 1) {
    return filterUrjanetAccountByCredential(credential, account);
  } else if (provider_type === 2) {
    return filterPlugAccountByCredential(credential, account, showSecondary);
  } else if (provider_type === 3) {
    return filterSolarAccountByCredential(credential, account);
  }
};

export const handleSetNewAccount = (
  provider_type,
  newAccount,
  setLocalUtilityAccounts
) => {
  if (provider_type === 1) {
    handleSetNewUrjanetAccount(newAccount, setLocalUtilityAccounts);
  } else if (provider_type === 2) {
    handleSetNewPlugAccount(newAccount, setLocalUtilityAccounts);
  }
};

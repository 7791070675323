import React, { useState, useEffect } from 'react';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  Legend,
  AreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts';

import CHARTCOLORS from '../helpers/chartColors';
import formatValue from '../helpers/chartFormatter';

export default function EnergyTracerStackChart(props) {
  const { data, unit, keys, x_axis_key } = props;

  const [chartDebounce, setChartDebounce] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setChartDebounce((prev) => prev + 1);
    }, 500);
  }, [data]);

  return (
    <ResponsiveContainer width={'100%'} height={300} debounce={chartDebounce}>
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey={x_axis_key}
          type='category'
          tick={{ fontSize: 16 }}
          interval={0}
        />
        <YAxis type='number' tickFormatter={(tick) => formatValue(tick, '')}>
          <Label value={''} position='top' offset={10} />
        </YAxis>
        <Tooltip wrapperStyle={{ zIndex: '200' }} />
        <Legend iconType='plainline' />
        {keys.map((key, index) => (
          <Area
            type='monotone'
            dataKey={key}
            key={key}
            formatter={(value) => formatValue(value, unit)}
            stroke={CHARTCOLORS[index % CHARTCOLORS.length]}
            fill={CHARTCOLORS[index % CHARTCOLORS.length]}
            stackId='1'
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
}

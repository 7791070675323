import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';

import { ApiServiceServerless } from '../../../xhr_libs';
import AddEntityActionButtons from '../../forms/AddEntityActionButtons';
import { SOLAR_PROVIDER_LOOKUP } from '../../UtilityImportSettings/helpers/solar';

export default function AddOrganizationSolarProvider(props) {
  const { show, onHide, organizationId, refreshData, setIsLoading, showToast } =
    props;

  const [localSolarProviderId, setLocalSolarProviderId] = useState('');
  const [localSolarProviderType, setLocalSolarProviderType] = useState(0);

  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const createSolarProvider = () => {
    setIsLocalLoading(true);
    ApiServiceServerless.post(
      `/solar/organization/${organizationId}/${localSolarProviderId}/${localSolarProviderType}`,
      {},
      { authorization_id: organizationId }
    )
      .then(() => {
        showToast('success', 'Success', 'Provider successfully added');
        refreshData(organizationId, showToast, setIsLoading);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
      })
      .finally(() => {
        setLocalSolarProviderId('');
        setLocalSolarProviderType(0);
        setIsLocalLoading(false);
        onHide();
      });
  };

  const actionsDisabled = localSolarProviderId === '';

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton> Add Solar Provider</Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            {'Solar Provider Id'}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <Form.Control
              required
              value={localSolarProviderId}
              onChange={(e) => setLocalSolarProviderId(e.target.value)}
            />
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Solar Provider Type</Form.Label>
          <Form.Control
            as='select'
            required
            value={localSolarProviderType}
            onChange={(e) =>
              setLocalSolarProviderType(parseInt(e.target.value))
            }
          >
            {Object.keys(SOLAR_PROVIDER_LOOKUP).map((opt) => (
              <option key={`day-opt-${opt}`} value={opt}>
                {SOLAR_PROVIDER_LOOKUP[opt]}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          onSubmit={createSolarProvider}
          isSubmitting={isLocalLoading}
          submitDisabled={actionsDisabled}
        />
      </Modal.Footer>
    </Modal>
  );
}

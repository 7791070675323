import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';

import { sortBy } from 'lodash';

import ApiServiceServerless from '../../../xhr_libs';
import SmallerLoader from '../../SmallerLoader';
import ConfirmationModal, { useConfirm } from '../ConfirmationModal';

const default_local_data = {
  meter_id: '',
  org_id: '',
  status: '',
  name: '',
  sub_account_id: '',
  organization_solar_provider_id: '',
  solar_active: true,
};

export default function SolarLinkAccountModal(props) {
  const {
    show,
    onHide,
    organization,
    modalData,
    subAccounts,
    setSubAccounts,
    updateAccess,
    activeLicense,
    showToast,
  } = props;

  const [localData, setLocalData] = useState({ ...default_local_data });

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isReSyncing, setIsReSyncing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [confirmationModalProps, withConfirm] = useConfirm();

  useEffect(() => {
    setLocalData({ ...modalData });
  }, [modalData]);

  const handleSetLocalData = (key, val) => {
    setLocalData((prev) => {
      let data = { ...prev };
      data[key] = val;
      return data;
    });
  };

  const handleLoading = (flag, val) => {
    if (flag === 'link') {
      setIsSaving(val);
    }
    if (flag === 'unlink') {
      setIsDeleting(val);
    }
    if (flag === 'resync') {
      setIsReSyncing(val);
    }
    if (flag === 'update') {
      setIsUpdating(val);
    }
  };

  const handleSubmit = (flag) => {
    handleLoading(flag, true);
    ApiServiceServerless.post(
      `/solar/link/${localData['sub_account_id']}/${flag}`,
      { ...localData, solar_active: true },
      {
        authorization_id: organization.id,
      }
    )
      .then((res) => {
        setSubAccounts((prev) =>
          prev.map((sub_account) => {
            if (sub_account.id !== res.data[0].id) return sub_account;
            return res.data[0];
          })
        );
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      })
      .finally(() => {
        onHide();
        handleLoading(flag, false);
      });
  };

  const handleUnlink = () => {
    const msg = `Are unlink this meter? This cannot be undone and will result in the deletion of bills.`;
    withConfirm(msg, () => {
      handleSubmit('unlink');
    });
  };

  const handleReSync = () => {
    handleLoading('resync', true);
    ApiServiceServerless.post(
      `/solar/repull/${localData['meter_id']}`,
      {},
      {
        authorization_id: organization.id,
      }
    )
      .then(() => {
        showToast(
          'success',
          'Success',
          'ReSync in progress. Check back in a few minutes.'
        );
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      })
      .finally(() => {
        onHide();
        handleLoading('resync', false);
      });
  };

  const handleUpdateStatus = (val) => {
    handleLoading('update', true);
    ApiServiceServerless.post(
      `/solar/meter/${localData['sub_account_id']}`,
      { solar_active: val },
      {
        authorization_id: organization.id,
      }
    )
      .then((res) => {
        setSubAccounts((prev) =>
          prev.map((sub_account) => {
            if (sub_account.id !== res.data[0].id) return sub_account;
            return res.data[0];
          })
        );
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      })
      .finally(() => {
        onHide();
        handleLoading('update', false);
      });
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>Account Import Settings</Modal.Header>

      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group controlId='solar.formGridNumber'>
              <Form.Label>LightLevel Meter Name </Form.Label>
              <Form.Control
                name='accountNumber'
                defaultValue={localData.name}
                disabled={true}
              />
            </Form.Group>
            <Form.Group controlId='solar.formSubAccountLink'>
              <Form.Label>Linked EnergyTracer SubAccount</Form.Label>
              <Form.Row>
                <Col>
                  <Form.Control
                    as='select'
                    name='linkedSubAccount'
                    value={localData.sub_account_id}
                    disabled={modalData.sub_account_id || !activeLicense}
                    onChange={(e) => {
                      handleSetLocalData('sub_account_id', e.target.value);
                    }}
                  >
                    <option key={'link-opt-null'} value={''} hidden>
                      -
                    </option>
                    {sortBy(
                      subAccounts.filter(
                        (sub_account) =>
                          (!sub_account.solar_meter_id ||
                            sub_account.solar_meter_id ===
                              localData.meter_id) &&
                          sub_account.provider_meter_id !== localData.meter_id
                      ),
                      ['utility_account_name', 'name']
                    ).map((sub_account) => (
                      <option
                        key={`link-opt-${sub_account.id}`}
                        value={sub_account.id}
                      >
                        {sub_account.utility_account_name +
                          ' (' +
                          sub_account.name +
                          ')'}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Row>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ width: '100%' }}>
          {updateAccess && activeLicense && (
            <>
              {modalData.sub_account_id !== '' && (
                <>
                  <Button
                    data-id='btn_delete'
                    type='button'
                    variant='danger'
                    className='float-left'
                    onClick={() => handleUnlink()}
                    disabled={isDeleting || isReSyncing || isUpdating}
                  >
                    {!isDeleting && <>{'Unlink'}</>}
                    {isDeleting && <SmallerLoader text={'Unlinking'} />}
                  </Button>
                  <Button
                    style={{ marginLeft: '0.5em' }}
                    data-id='btn_resync'
                    type='button'
                    variant='primary'
                    className='float-right'
                    onClick={() => handleReSync()}
                    disabled={isDeleting || isReSyncing || isUpdating}
                  >
                    {!isReSyncing && <>{'ReSync'}</>}
                    {isReSyncing && <SmallerLoader text={'ReSyncing...'} />}
                  </Button>
                  <Button
                    style={{ marginLeft: '0.5em' }}
                    data-id='btn_resync'
                    type='button'
                    variant='primary'
                    className='float-right'
                    onClick={() =>
                      handleUpdateStatus(!modalData['solar_active'])
                    }
                    disabled={isDeleting || isReSyncing || isUpdating}
                  >
                    {!isUpdating && (
                      <>
                        {modalData['solar_active'] ? 'Dectivate' : 'Activate'}
                      </>
                    )}
                    {isUpdating && <SmallerLoader text={'Updating...'} />}
                  </Button>
                </>
              )}
              {!modalData.sub_account_id && (
                <>
                  <Button
                    data-id='btn_save'
                    type='submit'
                    variant='primary'
                    className='float-right ml-3'
                    onClick={() => handleSubmit('link')}
                    disabled={isSaving}
                  >
                    {!isSaving && <>{'Link'}</>}
                    {isSaving && <SmallerLoader text={'Linking...'} />}
                  </Button>
                  <Button
                    data-id='btn_cancel'
                    type='button'
                    variant='outline-danger'
                    className='float-right'
                    onClick={() => onHide()}
                    disabled={isSaving}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </Modal.Footer>
      <ConfirmationModal {...confirmationModalProps} confirmText='Unlink' />
    </Modal>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import EnergyTracerMultiLineChart from '../../components/EnergyTracerMultiLineChart';
import CheckResources from './CheckResources';
import GraphActionContainer from '../../components/chartElements/GraphActionContainer';
import ChartDownload from '../../components/chartElements/Actions/ChartDownload';
import DashboardBaselineDateSelectContainer from './DashboardBaselineDateSelectContainer';
import EnergyTracerStackChart from '../../components/EnergyTracerStackedChart';

const CONSUMPTION_LOOKUP = {
  consumption1: 'Grid Purchased',
  consumption2: 'Solar Generation',
  consumption3: 'Solar Sellback',
};

const STACKED_KEYS = ['Grid Consumption', 'Solar Consumption'];

function SolarSummary(props) {
  const [deferredProps, setDeferredProps] = useState({ ...props });
  useEffect(() => {
    if (props.open) setDeferredProps({ ...props });
  }, [props]);
  const {
    dashboardData,
    organization,
    endDate,
    dateOption,
    activeIds,
    localBuildingIds,
  } = deferredProps;

  const [baselineData, setBaselineData] = useState({});

  const [solarMonthData, setSolarMonthData] = useState([]);

  const [solarKeys, setSolarKeys] = useState([]);
  const [chartDownloadHeaders, setChartDownloadHeaders] = useState([]);
  const [chartDownloadKeys, setChartDownloadKeys] = useState([]);

  const [solarStackedData, setSolarStackedData] = useState([]);

  const [stackedChartKeys, setStackedChartKeys] = useState([]);
  const [stackedChartBaselineKeys, setStackedChartBaselineKeys] = useState([]);
  const [stackedChartDownloadHeaders, setStackedChartDownloadHeaders] =
    useState([]);
  const [stackedChartDownloadKeys, setStackedChartDownloadKeys] = useState([]);

  // line chart download
  useEffect(() => {
    let headers = ['Date'];
    let keys = ['name'];
    solarKeys.forEach((key) => {
      headers.push(key);
      keys.push(key);
    });
    if (Object.keys(baselineData).length > 0) {
      solarKeys.forEach((key) => {
        headers.push(key + ' Baseline');
        keys.push(key + ' Baseline');
      });
    }
    setChartDownloadHeaders(headers);
    setChartDownloadKeys(keys);
  }, [solarKeys, baselineData]);

  // stacked chart download
  useEffect(() => {
    let headers = ['Date', STACKED_KEYS[0], STACKED_KEYS[1]];
    let download_keys = ['name', STACKED_KEYS[0], STACKED_KEYS[1]];

    let keys = [STACKED_KEYS[0], STACKED_KEYS[1]];
    let baseline_keys = [
      STACKED_KEYS[0] + ' Baseline',
      STACKED_KEYS[1] + ' Baseline',
    ];

    if (Object.keys(baselineData).length > 0) {
      headers.push(STACKED_KEYS[0] + ' Baseline');
      headers.push(STACKED_KEYS[1] + ' Baseline');
      download_keys.push(STACKED_KEYS[0] + ' Baseline');
      download_keys.push(STACKED_KEYS[1] + ' Baseline');
    }

    setStackedChartKeys(keys);
    setStackedChartBaselineKeys(baseline_keys);

    setStackedChartDownloadHeaders(headers);
    setStackedChartDownloadKeys(download_keys);
  }, [baselineData]);

  // line chart data
  useEffect(() => {
    const hasBaseline = Object.keys(baselineData).length > 0;

    let solar_keys = [];
    let local_ids = [...activeIds];

    let data = dashboardData['month_array'].map((month) => {
      return {
        name: month,
        month: month,
      };
    });
    data.forEach((entry, index) => {
      local_ids.forEach((id) => {
        let consumptions = {};
        let baseline_consumptions = {};
        if (
          dashboardData['building_usage'][id] &&
          dashboardData['building_usage'][id]['electric'] &&
          dashboardData['building_usage'][id]['electric']['consumptions']
        ) {
          consumptions =
            dashboardData['building_usage'][id]['electric']['consumptions'];
        }
        if (
          hasBaseline &&
          baselineData['building_usage'][id] &&
          baselineData['building_usage'][id]['electric'] &&
          baselineData['building_usage'][id]['electric']['consumptions']
        ) {
          baseline_consumptions =
            baselineData['building_usage'][id]['electric']['consumptions'];
        }

        Object.keys(consumptions).forEach((consumption) => {
          const lck = CONSUMPTION_LOOKUP[consumption]; //local consumption key
          if (
            !solar_keys.includes(lck) &&
            consumptions[consumption][index] !== 0
          ) {
            solar_keys.push(lck);
          }
          if (entry[lck]) {
            entry[lck] += consumptions[consumption][index];
          } else {
            entry[lck] = consumptions[consumption][index];
          }

          if (hasBaseline && Object.keys(baseline_consumptions).length > 0) {
            let blck = lck + ' Baseline'; //baseline lck
            if (entry[blck]) {
              entry[blck] += baseline_consumptions[consumption][index];
            } else {
              entry[blck] = baseline_consumptions[consumption][index];
            }
          }
        });
      });
    });
    setSolarKeys(solar_keys);
    setSolarMonthData(data);
  }, [dashboardData, activeIds, localBuildingIds, baselineData]);

  const KEY1 = 'Grid Consumption';
  const KEY2 = 'Solar Consumption';
  const KEYB1 = 'Grid Consumption Baseline';
  const KEYB2 = 'Solar Consumption Baseline';

  const getSolarStackedChartData = useCallback(
    (chart_data, dashboard_data, local_ids, baseline) => {
      chart_data.forEach((entry, index) => {
        local_ids.forEach((id) => {
          if (
            dashboard_data['building_usage'][id] &&
            dashboard_data['building_usage'][id]['electric'] &&
            dashboard_data['building_usage'][id]['electric']['consumptions']
          ) {
            let consumptions =
              dashboard_data['building_usage'][id]['electric']['consumptions'];

            let total_consumption =
              parseFloat(consumptions['consumption1'][index]) +
              parseFloat(consumptions['consumption2'][index]) +
              parseFloat(consumptions['consumption3'][index]);
            let solar_consumption = parseFloat(
              consumptions['consumption2'][index]
            );

            if (solar_consumption > total_consumption) {
              solar_consumption = total_consumption;
            }
            let grid_consumption = total_consumption - solar_consumption;

            if (baseline) {
              entry[KEYB1] += grid_consumption;
              entry[KEYB2] += solar_consumption;
            } else {
              entry[KEY1] += grid_consumption;
              entry[KEY2] += solar_consumption;
            }
          }
        });
      });
      return chart_data;
    },
    []
  );

  // stacked chart data
  useEffect(() => {
    const hasBaseline = Object.keys(baselineData).length > 0;

    let local_ids = [...activeIds];

    let data = dashboardData['month_array'].map((month) => {
      return {
        name: month,
        month: month,
        baseline_name: month,
        baseline_month: month,
        [KEY1]: 0,
        [KEY2]: 0,
        [KEYB1]: 0,
        [KEYB2]: 0,
      };
    });

    if (hasBaseline) {
      data.forEach((entry, index) => {
        if (dashboardData['month_array'].length >= index + 1) {
          entry['baseline_name'] = baselineData['month_array'][index];
          entry['baseline_month'] = baselineData['month_array'][index];
        }
      });
    }

    let solar_stacked_data = getSolarStackedChartData(
      data,
      dashboardData,
      local_ids,
      false
    );
    if (hasBaseline) {
      solar_stacked_data = getSolarStackedChartData(
        solar_stacked_data,
        baselineData,
        local_ids,
        true
      );
    }
    setSolarStackedData(solar_stacked_data);
  }, [
    dashboardData,
    activeIds,
    localBuildingIds,
    baselineData,
    getSolarStackedChartData,
  ]);

  return (
    <>
      <CheckResources
        organization={organization}
        dashboardData={dashboardData}
      />

      <br></br>
      <Row style={{ marginBottom: '2em' }}>
        <div style={{ marginBottom: '1em' }}>
          <h5 style={{ display: 'inline-block', paddingLeft: '2em' }}>
            {'Solar Data'}
          </h5>
          <div style={{ display: 'inline-block' }}>
            <DashboardBaselineDateSelectContainer
              orgId={organization.id}
              endDate={endDate}
              dateOption={dateOption}
              setBaselineData={setBaselineData}
              customStyle={{ marginLeft: '3em' }}
            />
          </div>
        </div>

        <div style={{ paddingLeft: '2em', width: '98%' }}>
          <EnergyTracerMultiLineChart
            data={!solarMonthData ? [] : solarMonthData}
            unit={'kWh'}
            keys={solarKeys}
            baseline={Object.keys(baselineData).length > 0}
            showSummary={false}
          />
        </div>
        <GraphActionContainer>
          <ChartDownload
            organization_id={organization.id}
            data={solarMonthData}
            headers={chartDownloadHeaders}
            keys={chartDownloadKeys}
            chart_type={'line'}
          />
        </GraphActionContainer>

        <div style={{ marginTop: '2em', width: '98%' }}>
          <h5 style={{ display: 'inline-block', paddingLeft: '2em' }}>
            {'Solar Usage Breakdown'}
          </h5>
          <div style={{ paddingLeft: '2em', width: '98%' }}>
            <EnergyTracerStackChart
              data={!solarStackedData ? [] : solarStackedData}
              unit={'kWh'}
              keys={stackedChartKeys}
              x_axis_key={'name'}
            />
          </div>
          {Object.keys(baselineData).length > 0 && (
            <>
              <h5 style={{ display: 'inline-block', paddingLeft: '2em' }}>
                {'Solar Usage Breakdown - Baseline'}
              </h5>
              <div style={{ paddingLeft: '2em', width: '98%' }}>
                <EnergyTracerStackChart
                  data={!solarStackedData ? [] : solarStackedData}
                  unit={'kWh'}
                  keys={stackedChartBaselineKeys}
                  x_axis_key={'baseline_name'}
                />
              </div>
            </>
          )}
        </div>
        <GraphActionContainer>
          <ChartDownload
            organization_id={organization.id}
            data={solarStackedData}
            headers={stackedChartDownloadHeaders}
            keys={stackedChartDownloadKeys}
            chart_type={'line'}
          />
        </GraphActionContainer>
      </Row>
    </>
  );
}

export default SolarSummary;

import dayjs from 'dayjs';

const EmptyBill = (type, unit, prev_read_date) => {
  let new_start_date = dayjs.utc(prev_read_date);
  new_start_date = new_start_date.day(new_start_date.day() + 1);
  const new_read_date = new_start_date.day(new_start_date.day() + 30);
  const new_bill_date = new_read_date;
  if (type === 'electric') {
    return {
      id: null,
      provider_meter_id: 'none',
      provider_bill_id: 'none',
      provider_bill_type: 0,
      bill_type: type,
      is_estimate: false,
      bill_date: new_bill_date,
      read_date: new_read_date,
      start_date: new_start_date,
      cost1_value: null,
      cost2_value: null,
      cost3_value: null,
      consumption1_value: null,
      consumption2_value: null,
      consumption3_value: null,
      demand1_value: null,
      demand2_value: null,
      cost1_unit: '$',
      cost2_unit: '$',
      cost3_unit: '$',
      consumption1_unit: unit,
      consumption2_unit: unit,
      consumption3_unit: unit,
      demand1_unit: 'kw',
      demand2_unit: 'kw',
    };
  } else if (type === 'gas') {
    return {
      id: null,
      provider_meter_id: 'none',
      provider_bill_id: 'none',
      provider_bill_type: 0,
      bill_type: type,
      is_estimate: false,
      bill_date: new_bill_date,
      read_date: new_read_date,
      start_date: new_start_date,
      cost1_value: null,
      cost2_value: null,
      cost3_value: null,
      consumption1_value: null,
      consumption2_value: null,
      demand1_value: null,
      demand2_value: null,
      cost1_unit: '$',
      cost2_unit: '$',
      cost3_unit: null,
      consumption1_unit: unit,
      consumption2_unit: unit,
      demand1_unit: null,
      demand2_unit: null,
    };
  } else if (type === 'water') {
    return {
      id: null,
      provider_meter_id: 'none',
      provider_bill_id: 'none',
      provider_bill_type: 0,
      bill_type: type,
      is_estimate: false,
      bill_date: new_bill_date,
      read_date: new_read_date,
      start_date: new_start_date,
      cost1_value: null,
      cost2_value: null,
      cost3_value: null,
      consumption1_value: null,
      consumption2_value: null,
      demand1_value: null,
      demand2_value: null,
      cost1_unit: '$',
      cost2_unit: null,
      cost3_unit: null,
      consumption1_unit: unit,
      consumption2_unit: unit,
      demand1_unit: null,
      demand2_unit: null,
    };
  } else if (type === 'propane') {
    return {
      id: null,
      provider_meter_id: 'none',
      provider_bill_id: 'none',
      provider_bill_type: 0,
      bill_type: type,
      is_estimate: false,
      bill_date: new_bill_date,
      read_date: new_read_date,
      start_date: new_start_date,
      cost1_value: null,
      cost2_value: null,
      cost3_value: null,
      consumption1_value: null,
      consumption2_value: null,
      demand1_value: null,
      demand2_value: null,
      cost1_unit: '$',
      cost2_unit: null,
      cost3_unit: null,
      consumption1_unit: unit,
      consumption2_unit: unit,
      demand1_unit: null,
      demand2_unit: null,
    };
  } else if (type === 'production') {
    return {
      id: null,
      provider_meter_id: 'none',
      provider_bill_id: 'none',
      provider_bill_type: 0,
      bill_type: type,
      is_estimate: false,
      bill_date: new_bill_date,
      read_date: new_read_date,
      start_date: new_start_date,
      cost1_value: null,
      cost2_value: null,
      cost3_value: null,
      consumption1_value: null,
      consumption2_value: null,
      demand1_value: null,
      demand2_value: null,
      cost1_unit: '$',
      cost2_unit: null,
      cost3_unit: null,
      consumption1_unit: unit,
      consumption2_unit: unit,
      demand1_unit: null,
      demand2_unit: null,
    };
  }
};

export default EmptyBill;
